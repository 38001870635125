import React, { useCallback, useEffect, useState } from 'react';
import { Container, Grid, InputAdornment, Tab, Tabs, TextField, Theme, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { TabContext, TabPanel } from "@mui/lab";
import {getAllBuyerBookings} from "../../components/bookedSpaces/request/booked-spaces-requests";
import { withSnackbar } from "../../components/hocs/withSnackbar";
import { getErrorMessageForNonStandardAndStandardResponse } from "../../util/NetworkErrorUtil";
import ActiveBookings from "../../components/bookingsOverview/ActiveBookings";
import PendingBookings from '../../components/bookingsOverview/PendingBookings';
import { Search } from "@mui/icons-material";
import type { Booking } from "../../types/Booking";
import type { Account } from "../../types/Account";
import InactiveBookings from "../../components/bookingsOverview/InactiveBookings";
import { formatCurrencyValue } from '../../util/PaymentUtils';
import { formatFrequency } from '@securspace/securspace-ui-kit';
import moment from 'moment';

export const showEndDate = (data) => {
  const {endDateAdjusted, bookingSubscriptionStatus, frequency} = data;
  const CANCELLED = 'CANCELLED';
  const RECURRING = 'RECURRING';

  const isOneTime = frequency !== RECURRING;
  const isCancelled = bookingSubscriptionStatus === CANCELLED;

  return (
      isCancelled ||
      isOneTime  ||
      (endDateAdjusted === true)
  );
}

export function transformBooking(booking) {
  const commonProperties = {
      initialCharge: formatCurrencyValue(booking.initialCharge, true),
      recurringCharge: formatCurrencyValue(booking.recurringCharge, true),
      rate: !booking.brokered ? `${formatCurrencyValue(booking.rate, true)}/${booking.durationType === 'MONTHLY' ? "month" : booking.durationType === 'DAILY' ? "day" : "week"}` : '',
      frequencyValue: formatFrequency(booking.frequency, booking.durationType),
      bookedDates: `${moment(booking.startDate).format("MM/DD/YYYY") || "N/A"} → ${showEndDate(booking) ? moment(booking.endDate).format("MM/DD/YYYY") : 'Until cancelled'}`
  };

  return {
      ...booking,
      ...commonProperties
  };
}

const useStyles: (theme: Theme) => { mainContainer: CSSStyleSheet, tabPanel: CSSStyleSheet } = makeStyles((theme) => ({
  mainContainer: {
    paddingTop: '2.5rem',
    paddingBottom: '2.86rem',
    paddingLeft: '1rem',
    paddingRight: '1rem',
    [theme.breakpoints.up('sm')]: {
      paddingLeft: '8.04rem',
      paddingRight: '8.04rem',
    }
  },
  tabPanel: {
    paddingLeft: '0',
    paddingRight: '0',
  },
}));

const BookingsOverview = (props: { initialTab: string, account: Account, snackbarShowMessage: () => void, readPendingBooking: () => {} }) => {
  const { initialTab = '0', account, snackbarShowMessage, readPendingBooking } = props;

  const urlParams = new URLSearchParams(window.location.search);
  const defaultSearch = urlParams?.get("bookingNumber") ? urlParams.get("bookingNumber") : "";

  const getDefaultTab = () => {
    if (urlParams) {
      let status = urlParams?.get("status");
      switch (status) {
        case "active":
          return 0;
        case "pending":
          return 1;
        case "inactive":
          return 2;
        default:
          return 0
      }
    } else {
      return initialTab
    }
  }

  const [currentTab, setCurrentTab] = useState(getDefaultTab() + '');
  const [bookings: Booking[], setBookings] = useState([]);
  const [activeBookings: Booking[], setActiveBookings] = useState([]);
  const [inactiveBookings, setInactiveBookings] = useState([]);
  const [pendingBookings: Booking[], setPendingBookings] = useState([]);
  const [reloadAfterUpdate, setReloadAfterUpdate] = useState(true);
  const [searchQuery, setSearchQuery] = useState(defaultSearch);
  const classes = useStyles();

  const headerCells = [
    { id: 'orderNumber', label: 'BOOKING NUMBER' },
    { id: 'frequencyValue', label: 'FREQUENCY' },
    { id: 'bookedDates', label: 'BOOKED DATES' },
    { id: 'locationName', label: 'LOCATION' },
    { id: 'recurringCharge', label: 'MONTHLY CHARGE' },
    { id: 'status', label: 'STATUS' },
    { id: 'supplierCompanyName', label: 'PARTNER' },
    { id: 'numberOfSpaces', label: 'SPACES BOOKED' },
    { id: 'paymentSchedule', label: 'PAYMENT SCHEDULE' },
    { id: 'initialCharge', label: 'INITIAL CHARGE' },
    { id: 'paymentMethodDescription', label: 'PAYMENT METHOD' },
    { id: 'assetType', label: 'EQUIPMENT TYPE' },
    { id: 'createdOn', label: 'CREATED ON' },
  ];

  const headerCellsWithRate = headerCells.concat({ id: 'rate', label: 'RATE' });
  
  const hasPendingStatus: (booking: Booking) => boolean = useCallback((booking) => {
    const { status } = booking;

    return status === "Pending" || status === "Incomplete"
  }, []);

  const loadBookings = useCallback(() => {
    if (account?.id && reloadAfterUpdate) {
      getAllBuyerBookings(account.id, 'startDate','desc').then((response) => {
        const bookings = response.body?.map((booking) => {
          return transformBooking(booking);
        })
        setBookings(bookings || []);

        const activeBookings = bookings.filter(booking => booking.active);
        const pendingBookings = bookings.filter(booking => !booking.active && hasPendingStatus(booking));
        const inactiveBookings = bookings.filter(booking => !booking.active && !hasPendingStatus(booking));

        setActiveBookings(activeBookings);
        setPendingBookings(pendingBookings);
        setInactiveBookings(inactiveBookings);
      }).catch((error) => {
        snackbarShowMessage(getErrorMessageForNonStandardAndStandardResponse(error));
      }).finally(() => {
      });
    }
    setReloadAfterUpdate(false);
  }, [account, reloadAfterUpdate, hasPendingStatus, snackbarShowMessage]);

  const handleTabChange = (_event, value) => {
    if (value !== undefined) {
      setCurrentTab(value);
    }
  };

  const handleSearchQueryChange = (event) => {
    const { value } = event.target;
    setSearchQuery(value);
  };

  const handleActionSuccess = () => {
    readPendingBooking();
    setReloadAfterUpdate(true);
  };

  useEffect(() => {
    loadBookings();
  }, [reloadAfterUpdate]);
  // setSearchQuery to defaultSearch on load & trigger search change

  useEffect(() => {
    let active: Booking[] = [];
    let pending: Booking [] = [];
    let inactive: Booking [] = [];

    if (searchQuery) {
      let filterTokens = searchQuery.split(" ").map(value => value.toLocaleLowerCase());

      bookings.forEach((booking: Booking) => {
        for (let token of filterTokens) {
          if (booking.orderNumber?.toLocaleLowerCase().includes(token) ||
            booking.assetType?.toLocaleLowerCase().includes(token) ||
            booking.locationName?.toLocaleLowerCase().includes(token) ||
            booking.locationCity?.toLocaleLowerCase().includes(token) ||
            booking.locationState?.toLocaleLowerCase().includes(token) ||
            booking.locationAddressLine1?.toLocaleLowerCase().includes(token) ||
            booking.startDate.includes(token) ||
            booking.endDate.includes(token)
          ) {
            if (booking.active) {
              active.push(booking);
            } else if (hasPendingStatus(booking)) {
              pending.push(booking);
            } else {
              inactive.push(booking)
            }
            break;
          }
        }
      });

    } else {
      bookings.forEach((booking: Booking) => {
        if (booking.active) {
          active.push(booking);
        } else if (hasPendingStatus(booking)) {
          pending.push(booking);
        } else {
          inactive.push(booking)
        }
      });
    }
    setActiveBookings(active);
    setPendingBookings(pending);
    setInactiveBookings(inactive);

  }, [searchQuery, bookings, currentTab]);

  return (
    <Container className={classes.mainContainer}>
      <Grid item>
        <Typography variant={'h5'} component={'h1'}>My Bookings</Typography>
      </Grid>
      <Grid item>
        <TabContext value={currentTab}>
          <Grid container justifyContent={'space-between'} alignItems={'center'}>
            <Grid item xs md={6}>
              <Tabs
                value={currentTab}
                onChange={handleTabChange}
                aria-label={'My Bookings Tabs'}
                textColor={'secondary'}
                variant={'fullWidth'}
              >
                <Tab value={'0'} label={activeBookings.length ? `Active (${activeBookings.length})` : 'Active'} />
                <Tab value={'1'} label={pendingBookings.length ? `Pending (${pendingBookings.length})` : 'Pending'} />
                <Tab value={'2'} label={inactiveBookings.length ? `Inactive (${inactiveBookings.length})` : 'Inactive'} />
              </Tabs>
            </Grid>
            <Grid item xs={12} md={3}>
              <TextField
                name={'searchQuery'}
                value={searchQuery}
                onChange={handleSearchQueryChange}
                label={'Search'}
                placeholder={'Enter your query here'}
                InputProps={{
                  endAdornment: <InputAdornment position={'end'}>
                    <Search />
                  </InputAdornment>
                }}
                variant={'standard'}
                fullWidth
              />
            </Grid>
          </Grid>

          <TabPanel value={'0'} className={classes.tabPanel}>
            <ActiveBookings bookings={activeBookings} onActionSuccess={handleActionSuccess} account={account} headerCells={headerCellsWithRate} />
          </TabPanel>
          <TabPanel value={'1'} className={classes.tabPanel}>
            <PendingBookings bookings={pendingBookings} account={account} onActionSuccess={handleActionSuccess} headerCells={headerCells}/>
          </TabPanel>
          <TabPanel value={'2'} className={classes.tabPanel}>
            <InactiveBookings bookings={inactiveBookings} onActionSuccess={handleActionSuccess} account={account} searchQuery={searchQuery} headerCells={headerCellsWithRate} />
          </TabPanel>
        </TabContext>
      </Grid>
    </Container>
  );
};

export default withSnackbar(BookingsOverview);
