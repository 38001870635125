import { LogoType } from "../../components/constants/securspace-constants";

export type Nav = {
    badge?: boolean,
    exact: boolean,
    highlighted: boolean,
    label: string,
    logoType: string,
    path: string
};

const defaultNavConfig = {
    highlighted: false,
    logoType: LogoType.LOGO_TRANSPARENT,
    exact: false
}

const makeNavConfig = (config: {
    path: String,
    label: String }) => {

    return {
        ...defaultNavConfig,
        ...config
    }
}

const makeNav = (path: String, label: String, config?: {}) => {
    let newConfig = { path, label }
    if(config) {
        newConfig = { ...newConfig, ...config }
    }
    return makeNavConfig(newConfig)
}

export const LandingNav = Object.freeze({
    LIST_YOUR_LOCATION: makeNav('partner-signup', 'List Your Location'),
    SEARCH: makeNav('search', 'Search'),
    ABOUT: makeNav('about', 'About'),
    CONTACT: makeNav('contact', 'Contact'),
    FAQ: makeNav('faq', 'Faq'),
    LOG_IN: makeNav('login', 'Log In'),
    REGISTER_AS_CUSTOMER: makeNav('customer-signup', 'Register as Customer'),
    REGISTER_AS_PARTNER: makeNav('partner-signup', 'Register as Partner'),
    RESOURCES_AS_BUYER: makeNav('resources-buyer', 'Resources'),
    RESOURCES_AS_SUPPLIER: makeNav('resources-supplier', 'Resources'),
})
export const LandingNavHome = Object.freeze({
    LIST_YOUR_LOCATION: makeNav('partner-signup', 'List Your Location'),
    SEARCH: makeNav('search', 'Search'),
    ABOUT: makeNav('about', 'About'),
    HOW_IT_WORKS: makeNav('#how-it-works', 'How It Works'),
    CONTACT: makeNav('contact', 'Contact'),
    FAQ: makeNav('faq', 'Faq'),
    LOG_IN: makeNav('login', 'Log In'),
    REGISTER: makeNav('signup', 'Register'),
    REGISTER_AS_CUSTOMER: makeNav('customer-signup', 'Register as Customer'),
    REGISTER_AS_PARTNER: makeNav('partner-signup', 'Register as Partner'),
})

export const AccountNav = Object.freeze({
    COMPANY_PROFILE: makeNav('company-profile', 'Company'),
    LOCATIONS_PROFILE: makeNav('locations-profile', 'Locations'),
    USER_MANAGEMENT: makeNav('user-management', 'Users'),
    NOTIFICATIONS_SETTINGS: makeNav('notification-settings', 'Notifications'),
    SIGN_OUT: makeNav('logout', 'Sign Out')
})

export const AdminNav = Object.freeze({
    ADMIN_REPORTS: makeNav('admin-reports', 'CSV Reports'),
    ADMIN_ACCOUNTS: makeNav('admin-accounts', 'Accounts'),
    ADMIN_BOOKINGS: makeNav('admin-bookings', 'Bookings'),
    ADMIN_LOCATIONS: makeNav('admin-locations', 'Locations'),
    ADMIN_INVOICES: makeNav('admin-invoices', 'Invoices'),
    ADMIN_PENDING_CHARGES: makeNav('admin-pending-charges', 'Pending Charges'),
    ADMIN_READY_FOR_PAYOUT: makeNav('admin-ready-for-payout', 'Ready For Payout'),
    ADMIN_PAYOUTS: makeNav('admin-payouts', 'Payouts'),
    ADMIN_BOOKED_SPACE_CALENDAR: makeNav('admin-booked-space-calendar', 'Booked Space Calendar'),
    LOGIN_AS_ACCOUNT: makeNav('login-as-account', 'Log In As Account')
})

export const BuyerNav = Object.freeze({
    MY_BOOKINGS: makeNav('my-bookings', 'My Bookings', { badge: true }),
    BUYER_INVENTORY: makeNav('buyer-inventory', 'Current Inventory'),
    BUYER_INTERCHANGES: makeNav('buyer-interchanges', 'Interchanges'),
    BUYER_INVOICES: makeNav('buyer-invoices', 'Invoices'),
    BUYER_BOOKED_SPACE_CALENDAR: makeNav('buyer-booked-space-calendar', 'Booked Space Calendar'),
    BUYER_INVENTORY_CORRECTIONS: makeNav('buyer-inventory-corrections', 'Inventory Corrections', { badge: true })
})

export const GateManagementNav = Object.freeze({
    CHECK_IN: makeNav('check-in', 'Check In'),
    SUPPLIER_INVENTORY: makeNav('supplier-inventory', 'Current Inventory'),
    SUPPLIER_INTERCHANGES: makeNav('supplier-interchanges', 'Interchanges'),
    SUPPLIER_ACTIVITY_SUMMARY: makeNav('supplier-activity-summary', 'Activity Summary'),
    INVENTORY_CORRECTION: makeNav('inventory-corrections', 'Inventory Corrections', { badge: true })
})

export const SupplierNav = Object.freeze({
    GMS_LANDING: makeNav('gms-landing', 'Gate Management'),
    APPROVALS: makeNav('approvals', 'Approvals', { badge: true }),
    SUPPLIER_BOOKINGS: makeNav('supplier-bookings', 'All Bookings'),
    SUPPLIER_INVOICES: makeNav('supplier-invoices', 'Invoices'),
    READY_FOR_PAYOUT: makeNav('ready-for-payout', 'Ready For Payout'),
    SUPPLIER_PAYOUTS: makeNav('supplier-payouts', 'Payouts'),
    PARTNER_SUBSCRIPTIONS: makeNav('subscriptions', 'Subscriptions'),
    SUPPLIER_BOOKED_SPACE_CALENDAR: makeNav('supplier-booked-space-calendar', 'Booked Space Calendar')
})

export const MarketplaceOnlyGmsUserNav = Object.freeze({
    GMS_UPGRADE: makeNav('gms-upgrade', "Upgrade Required")
})
