import React from 'react'
import DynamicPage from "../../../views/DynamicPage";
import PropTypes from 'prop-types'

const ContextualDynamicPage = (props) => {
    const { cancel = () => {}, type = 'terms' } = props
    return <DynamicPage
      {...props}
      cancel={cancel}
      type={type}
    />
}

ContextualDynamicPage.propTypes = {
    cancel: PropTypes.func.isRequired,
    type: PropTypes.string.isRequired
}

export default ContextualDynamicPage
