import React, {Component, useContext} from 'react';
import '../css/views/landing.css';
import RedirectToSearch from "../components/RedirectToSearch";
import PopularLocationListing from "../components/PopularLocationListing";
import RecentlyBookedCarousel from '../components/landing/recentlyBooked/RecentlyBookedCarousel';
import {Helmet} from "react-helmet";
import Search from "./Search";
import {GlobalModalContext} from "../context/global-modal-context";
import {AppContext} from "../context/app-context";
import {useNavigate} from "react-router";
import SearchUtilService from "../services/search/SearchUtilService";
import Testimonials from '../components/testimonials/Testimonials';
import PropTypes from 'prop-types'
import LandingHeader from "../components/landing/LandingHeader";
import HowItWorks from "./how-it-works/HowItWorks";

const $ = window.$;
const google = window.google;

class Landing extends Component {
    static contextType = GlobalModalContext

    static propTypes = {
        user: PropTypes.object,
        showSignUpModal: PropTypes.func.isRequired,
        history: PropTypes.func.isRequired
    }

    constructor(props) {
        super(props);

        this.state = {
            ...SearchUtilService.getDefaultLandingSearchContext(),
            navToSearch: false,
            url: window.location.href,
        };
    }

    componentDidMount() {


        this.initDatePickers();
        let locationInputs = document.getElementsByName('landingSearchLocationName');
        for (let i = 0; i < locationInputs.length; i++) {
            this.pacSelectFirst(locationInputs[i]);
            this.addAutocompleteListener(locationInputs[i]);
        }
    }

    addAutocompleteListener(locationInput) {
        let _this = this;
        const options = {
            types: ['(cities)']
        };
        const autocomplete = new google.maps.places.Autocomplete(locationInput, options);
        autocomplete.addListener('place_changed', function () {
            let place = autocomplete.getPlace();
            let addressParts = Search.getAddressParts(place);
            let foundLocation = Landing.getLocation(place);
            if (foundLocation) {
                _this.setState({
                    landingSearchLocationName: place.formatted_address,
                    landingSearchLocationCityState: addressParts.cityState,
                    location: foundLocation
                });
            } else {
                _this.setState({location: null});
            }
        });
    }

    pacSelectFirst(input) {
        let _this = this;
        // store the original event binding function
        let _addEventListener = (input.addEventListener) ? input.addEventListener : input.attachEvent;

        function addEventListenerWrapper(type, listener) {
            // Simulate a 'down arrow' keypress on hitting 'return' when no pac suggestion is selected,
            // and then trigger the original listener.
            if (type === "keydown") {
                let orig_listener = listener;
                listener = function (event) {
                    let suggestion_selected = $(".pac-item-selected").length > 0;
                    if (event.which === 13 && !suggestion_selected) {
                        let visibleAutocompleteDropdowns = $('.pac-container:visible');
                        if (visibleAutocompleteDropdowns && visibleAutocompleteDropdowns.length > 0) {
                            let simulated_downarrow = $.Event("keydown", {
                                keyCode: 40,
                                which: 40
                            });
                            orig_listener.apply(input, [simulated_downarrow]);
                        } else {
                            _this.handleSearch();
                        }
                    }

                    orig_listener.apply(input, [event]);
                };
            }

            _addEventListener.apply(input, [type, listener]);
        }

        input.addEventListener = addEventListenerWrapper;
        input.attachEvent = addEventListenerWrapper;
    }

    static getLocation(place) {
        let locationGeometry = place ? place.geometry : null;
        return locationGeometry ? locationGeometry.location : null;
    }

    initDatePickers() {
        $('#landingSearchStartDate').datepicker({
            format: 'm/d/yyyy',
            startDate: new Date()
        }).on('changeDate', this.handleChange);
        $('#landingSearchEndDate').datepicker({
            format: 'm/d/yyyy',
            startDate: new Date()
        }).on('changeDate', this.handleChange);
        $('#landingInlineSearchDatesFieldset').datepicker({
            inputs: $('#landingSearchStartDate, #landingSearchEndDate')
        });

    }

    handleChange = event => {
        let id = event.target.id;
        let name = event.target.name;
        let value = (event.target.type === 'checkbox') ? event.target.checked : event.target.value;
        if ('landingSearchNumberOfSpaces' === name && value && (!Landing.isInteger(value) || value <= 0 || value > 9999)) {
            return;
        }
        if ('landingSearchLocationName' === name) {
            this.setState({location: null});
        }
        if ('landingSearchStartDate' === id) {
            $('#landingSearchEndDate').focus();
        }
        this.setState({[name]: value});
    };

    static isInteger(x) {
        return x % 1 === 0;
    }

    handleSearch = () => {
        //If a search is performed when the Number Of Spaces field is empty, just default it to 1
        let numberOfSpaces = this.state.landingSearchNumberOfSpaces ? this.state.landingSearchNumberOfSpaces : 1;
        this.setState({
            navToSearch: true,
            landingSearchNumberOfSpaces: numberOfSpaces
        })
    }

    render() {
        $(document).delegate('.trigger-click', 'click', function () {
            let element = $(this).find('input');
            $(element[0]).focus();
        });

        return (
            <div id="ssLanding" className="ss-main ss-horizontal">
                <Helmet>
                    <title>SecurSpace | Nationwide Truck Parking & Storage</title>
                    <meta name="keywords" content="truck parking"/>
                    <meta name="description"
                          content="SecurSpace is an online marketplace that connects companies looking for parking and storage options to those with excess capacity."/>
                    <link rel="canonical" href={this.state.url}/>
                </Helmet>
                <RedirectToSearch navToSearch={this.state.navToSearch}/>
                <LandingHeader/>
                <RecentlyBookedCarousel/>
                <PopularLocationListing/>
                <HowItWorks/>
                <Testimonials/>
            </div>
        )
    }
}

const ContextualLandingWrapper = (props) => {
    const appContext = useContext(AppContext)
    const {user} = appContext

    const globalModalContext = useContext(GlobalModalContext)
    const {showSignUpModal} = globalModalContext

    const history = useNavigate()

    return (
        <Landing
            {...props}
            user={user}
            showSignUpModal={showSignUpModal}
            history={history}
        />
    )
}

export default ContextualLandingWrapper;
