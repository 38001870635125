import React, { useContext } from 'react'
import { AppContext } from "../../context/app-context";
import { ModalRoute } from '../../react-router-modal'
import { useNavigate } from "react-router-dom";
import { getLandingRedirectPathForUser } from "../route-utils";
// import ContextualSignUp from "../../components/modals/context-wrappers/ContextualSignUp";
import ContextualAccountConfirmation from "../../components/modals/context-wrappers/ContextualAccountConfirmation";
import ContextualDynamicPage from "../../components/modals/context-wrappers/ContextualDynamicPage";
import { DefaultModalClassNames } from "../../components/modals/modals";
import { useFlags } from 'launchdarkly-react-client-sdk';
// import { LOGIN_PATH } from '../../components/constants/securspace-constants';

const ModalContent = () => {

    const appContext = useContext(AppContext)
    const { user } = appContext
    const history = useNavigate()

    const globalCancel = () => history(getLandingRedirectPathForUser(user))

    const { policyDocumentsRedesign } = useFlags();

    const legacyPolicyDocRoutes = (policyDocumentsRedesign !== undefined) && !policyDocumentsRedesign ? [
        <ModalRoute path="/terms-of-use/:version?"
            parentPath="/" key="modal_/terms_of_user"
            {...DefaultModalClassNames}
            className="app-modal modal-page">
            <ContextualDynamicPage type="terms" cancel={globalCancel} />
        </ModalRoute>,
        <ModalRoute path="/privacy-policy/:version?"
            parentPath="/" key="modal_/privacy-policy"
            {...DefaultModalClassNames}
            className="app-modal modal-page">
            <ContextualDynamicPage type="privacy-policy" cancel={globalCancel} />
        </ModalRoute>
    ] : [];

    return [
        // <ModalRoute path="/signup"
        //     parentPath={getLandingRedirectPathForUser(user)}
        //     key="modal_/signup"
        //     {...DefaultModalClassNames}>
        //     <ContextualSignUp
        //         cancel={globalCancel}
        //         handleNavigateToSignup={() => history.push("/signup")}
        //         handleNavigateToLogin={() => history.push(LOGIN_PATH)}
        //     />
        // </ModalRoute>,
        <ModalRoute path="/confirm-account"
            parentPath="/"
            key="modal_/confirm_account"
            onBackdropClick={() => { }}
            {...DefaultModalClassNames}>
            <ContextualAccountConfirmation />
        </ModalRoute>,
        ...legacyPolicyDocRoutes
    ];
}


export default ModalContent
