const isSupportedFileType = (file) => {
  let fileType = file['type'];
  let ValidFileTypes = ['image/gif', 'image/jpeg', 'image/png', 'application/pdf'];
  return $.inArray(fileType, ValidFileTypes) >= 0;
}

export const validateFiles = (allUploadFiles) => {
  for (let i = 0; i < allUploadFiles.length; i++) {
    let fileToUpload = allUploadFiles[i];
    let file = fileToUpload.files ? fileToUpload.files[0] : '';
    if (file.size > 20000000) {
      return false
    }
    if (fileToUpload.isImageFile) {
      if (file && !isSupportedFileType(file)) {
        return false;
      }
    }
  }
  return true;
}

export const validateFile = (uploadFile) => {
  if (uploadFile.size > 20000000) {
    return false
  }
  if (uploadFile.isImageFile) {
    if (uploadFile && !isSupportedFileType(uploadFile)) {
      return false;
    }
  }
  return true;
}

export const downloadCSVFile: (content: any, fileName: string) => void = (content, fileName) => {
  if (content) {
    const blob = new Blob([content], {type: 'text/csv'});
    const doc = document.createElement("a");
    doc.download = fileName;
    doc.href = window.URL.createObjectURL(blob);
    const clickEvt = new MouseEvent('click', {
      view: window,
      bubbles: true,
      cancelable: true,
    })
    doc.dispatchEvent(clickEvt);
    doc.remove();
  }
}