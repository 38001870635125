import moment from 'moment';
import request from '../../../util/SuperagentUtils';
import { DateFormats } from '../../constants/securspace-constants';


const getAllBuyerInventory = (
    searchParams,
    onSuccess,
    onFail
) => {

    const { buyerAccountId, page, size, startDate, endDate, locationId, bookingNumber, sortBy, supplierName, equipmentType, equipmentNumber, driverName, sortDir } = searchParams;

    let pageParam = ["page", page || 0];
    let sizeParam = ["size", size || 10];

    let params = new URLSearchParams([pageParam, sizeParam]);

    if (startDate) {
        params.append("startDate", moment(startDate).format(DateFormats.DAY));
    }
    if (endDate) {
        params.append("endDate", moment(endDate).format(DateFormats.DAY));
    }
    if (locationId && locationId !== 'all') {
        params.append("locationId", locationId)
    }
    if (bookingNumber) {
        params.append("bookingNumber", bookingNumber)
    }
    if (driverName) {
        params.append("driverName", driverName)
    }
    if (sortBy && sortBy !== 'none') {
        params.append("sortBy", sortBy)
    }
    if (supplierName) {
        params.append("supplierName", supplierName?.trim())
    }
    if (equipmentType && equipmentType !== 'All') {
        params.append("equipmentType", equipmentType)
    }
    if (equipmentNumber) {
        params.append("equipmentNumber", equipmentNumber)
    }
    if(sortDir) {
        params.append("sortDir", sortDir)
    }

    const result = request.get(`/api/inventory/buyers/${buyerAccountId}?${params}`);

    if (onSuccess && onFail) {
        result
            .then(onSuccess)
            .catch(onFail)
    } else {
        return result
    }
}

export {
    getAllBuyerInventory
}