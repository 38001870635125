import React, {Component} from 'react'
import { GlobalModalContext } from "../../context/global-modal-context";
import '../../css/components/footer.css';
import FooterLinks from "./FooterLinks";
import FooterSocialMediaIcons from "./FooterSocialMediaIcons";
import { Typography } from '@mui/material';
import { withRouter } from '../../hooks/withRouter';

class Footer extends Component {

    static contextType = GlobalModalContext

    render() {
        const {location}= this.props;
        const globalModalContext = this.context
        const { showTermsModal, showPrivacyModal } = globalModalContext
        return (
            <footer className='ss-footer'>
                <section className="ss-footer-section">
                <img id="navLogoTrans"
                        src={`/app-images/logo/CW_Landside-logo.svg`}
                        alt="SecūrSpace" />
                    <FooterLinks
                        location={location}
                        showTermsModal={showTermsModal}
                        showPrivacyModal={showPrivacyModal}
                    />
                    <div className="ss-footer-social-icons">
                        <FooterSocialMediaIcons/>
                    </div>
                    <div className="ss-footer-icon-container">
                        <Typography variant="caption" color="textSecondary" className="footer-copyright">&copy; SecūrSpace {new Date().getFullYear()} - All rights reserved</Typography>
                    </div>
                </section>
            </footer>
        )
    }
}

export default withRouter(Footer);
