import React from 'react';
import PropTypes from 'prop-types';
import BrokeredBookingTogglePanel from './BrokeredBookingTogglePanel';
import ChargeOveragesForm from './ChargeOveragesForm';
import PayoutSummaryInfoPanel from '../PayoutSummaryInfoPanel';
import AdminInfoPanel from '../../admin/AdminInfoPanel';
import { Alert } from '@mui/material';

const AdminBrokeredBookingFormSection = (props) => {
  const {
    user,
    location,
    isBrokeredBooking,
    locationChargeOverages,
    chargeOverages,
    setChargeOverages,
    customerChargedAmountPerPeriod,
    partnerPayoutAmountPerPeriod,
    overageCustomerChargeRate,
    overagePartnerPayoutRate,
    onCheckBoxChange,
    onTextChange,
    durationType,
    manualPayment
  } = props;

  const securSpaceAmount = customerChargedAmountPerPeriod - partnerPayoutAmountPerPeriod;

  return (
    <>
      <BrokeredBookingTogglePanel isBrokeredBooking={isBrokeredBooking} onChange={onCheckBoxChange} />
      {
        isBrokeredBooking && <ChargeOveragesForm
          disabled={!location.chargeOverages}
          locationChargeOverages={locationChargeOverages}
          chargeOverages={chargeOverages}
          setChargeOverages={setChargeOverages}
          customerChargedAmountPerPeriod={customerChargedAmountPerPeriod}
          partnerPayoutAmountPerPeriod={partnerPayoutAmountPerPeriod}
          overageCustomerChargeRate={overageCustomerChargeRate}
          overagePartnerPayoutRate={overagePartnerPayoutRate}
          onCheckboxChange={onCheckBoxChange}
          onTextChange={onTextChange}
          durationType={durationType}
          manualPayment={manualPayment}
        />
      }
      {
        isBrokeredBooking && (securSpaceAmount < 0) && <Alert severity="error">
          Partner payout amount is greater than customer charged amount.
        </Alert>
      }
      <PayoutSummaryInfoPanel
        customerAmount={customerChargedAmountPerPeriod}
        partnerAmount={partnerPayoutAmountPerPeriod}
        securSpaceAmount={securSpaceAmount}
      />
      <AdminInfoPanel user={user} location={location} />
    </>
  );
};

AdminBrokeredBookingFormSection.propTypes = {
  user: PropTypes.object.isRequired,
  location: PropTypes.object,
  isBrokeredBooking: PropTypes.bool.isRequired,
  onCheckBoxChange: PropTypes.func.isRequired,
  onTextChange: PropTypes.func.isRequired,
  durationType: PropTypes.string.isRequired,
};

export default AdminBrokeredBookingFormSection;
