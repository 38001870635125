import React, {Component} from 'react';
import ExternalLanding from "./ExternalLanding";

class Contact extends Component {
    render() {
        return <ExternalLanding url="https://launch.secur.space/contact-us" height="100%" />
    }
}

export default Contact;
