import { Container, Grid, Paper, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';
import './style.css';
import '../../components/testimonials/testimonials.css';
import TestimonialItemList from '../../components/testimonials/TestimonialItemList';
import TopTier from '../../components/testimonials/topTier/TopTier';

const useStyles = makeStyles((theme) => ({
    headerTitle: {
        color: '#FFFFFF',
        textShadow: '0rem 0.29rem 0.29rem rgba(0, 0, 0, 0.25)',
        fontSize: '4.57142rem',
        wordWrap: 'break-word',
    },
    aboutSection: {
        marginTop: '1.43rem',
    },
    aboutSecurSpace: {
        paddingTop: '3.71rem',
        paddingRight: '5%',
        paddingLeft: '5%',
        paddingBottom: '3.71rem',
        display: 'flex',
        flexDirection: 'column',
        [theme.breakpoints.up('md')]: {
            padding: '3.71rem 5.14rem 0 5.14rem',
            height: '38.29rem',
        }
    },
    truckImage: {
        borderRadius: '0.71rem',
        display: 'flex',
        alignSelf: 'center',
        position: 'relative',
        marginTop: '2.15rem',
        objectfit: 'contain',
        height: 'auto',
        width: '100%',
        [theme.breakpoints.up('md')]: {
            maxHeight: '33.5rem',
            width: '100%',
            objectfit: 'cover',
        }
    },
    testimonialsSection: {
        marginTop: '7.07rem', // manually push section down b.c. truck image overflow
        [theme.breakpoints.up('md')]: {
            marginTop: '19.07rem'
        }
    },
    testimonialsDescription: {
        color: theme.palette.text.disabled,
    },
    aboutText: {
        fontSize: '1.71em',
        fontWeight: '400',
        lineHeight: '133.4%',
        color: '#000000',
        fontFamily: 'Inter',
    },
    appContainer:{
        maxWidth: '92.71rem !important',
    }
}));

const About = () => {
    const classes = useStyles();

    return (
        <article className='ss-about w-100'>
            <Container className={classes.appContainer}>
                <Typography variant='h1' className={classes.headerTitle} align='center'>About SecurSpace</Typography>
                <Grid container justifyContent='center' component="section" className={classes.aboutSection}>
                    <Paper elevation={1} className={classes.aboutSecurSpace}>
                        <Typography variant='h5' component='p' align='center' className={classes.aboutText}>
                            SecūrSpace is an online marketplace that connects companies looking for parking and storage options to those with dedicated or excess capacity. Our platform was built to combat issues caused by the lack of adequate space in cities and near major highways and interstates. Businesses and individuals around the world contend with this issue on a daily basis - solving this problem is our specialty.
                        </Typography>
                        <img className={classes.truckImage} src='/app-images/about/image-trucks.png' alt='trucks' />
                    </Paper>
                </Grid>
                <section className={classes.testimonialsSection}>
                    <div className="ss-customer-partner-testimonials-head">
                        <Typography variant="h3" color="textPrimary" component="h2" align='center'>Supported by top-tier buyers and suppliers across the globe</Typography>
                        <Typography variant="overline" className={classes.testimonialsDescription}>Testimonials</Typography>
                    </div>
                    <TestimonialItemList />
                    <TopTier />
                </section>
            </Container>
        </article>
    );
};

export default About;