export const formatSpaceUsageItem = (spaceUsageItem) => {

    const {calendarDate, buyerCompanyName, totalSpacesBooked, maxInventoryCount, overageSpacesChargedCount, locationName, supplierCompanyName, overageInvoiceNumbers, bookingNumbers} = spaceUsageItem;

    const calculateOveragesSpaces = () => {
        let {maxInventoryCount, totalSpacesBooked} = spaceUsageItem;
        return maxInventoryCount > totalSpacesBooked ? maxInventoryCount - totalSpacesBooked : 0;
    };

    const calculateUnchargedOverages = () => {
        let overagesSpaces = calculateOveragesSpaces();
        let {overageSpacesChargedCount} = spaceUsageItem;
        return  overagesSpaces - overageSpacesChargedCount;
    };

    return {
        date: calendarDate,
        bookedSpaces: totalSpacesBooked,
        maxInventory: maxInventoryCount,
        overageSpaces: calculateOveragesSpaces(),
        invoicedOverages: overageSpacesChargedCount,
        unchargedOverages: calculateUnchargedOverages(),
        customer: buyerCompanyName,
        location: locationName,
        partner: supplierCompanyName,
        overageInvoices: overageInvoiceNumbers,
        bookings: bookingNumbers,
    };
    
}

export const sortByFields = [
    {label: "Supplier", value: "supplierCompanyName"}, 
    {label: "Bookings", value: "bookingNumbers"}, 
    {label: "Location", value: "locationName"}, 
    {label: "Overage Invoices", value: "overageInvoiceNumbers"}, 
    {label: "Booked Spaces", value: "totalSpacesBooked"}, 
    {label: "Date", value: "calendarDate"},
    {label: "Max Inventory", value: "maxInventoryCount"}, 
    {label: "Charged Overages", value: "overageSpacesChargedCount"}, 
]

export const getSortByField = (label) => {
    return sortByFields.find(item => item.label === label)?.value;
}
