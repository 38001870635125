import React, { useContext, useEffect, useState } from 'react'
import { Button, Checkbox, FormControlLabel, Grid, InputAdornment, Paper, TextField, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Link } from 'react-router-dom';
import URLUtils from '../../util/URLUtils';
import { useNavigate, useLocation } from "react-router-dom";
import { getLandingRedirectPathForUser } from '../../routing/route-utils';
import { AppContext } from '../../context/app-context';
import SessionPoller from '../../services/session/SessionPoller';
import { LOGIN_PATH } from '../../components/constants/securspace-constants';
import Busy from '../../components/Busy';
import { REDIRECT_REFERRER } from '../../components/constants/local-storage-constants';
import classNames from 'classnames';
import {Theme} from "@mui/material";

const $ = window.$;

const useStyles: (theme: Theme) => {
    loginContainer: CSSStyleSheet,
    loginFormContainer: CSSStyleSheet,
    loginMessageContainer: CSSStyleSheet,
    forgetPasswordFont: CSSStyleSheet,
    loginFooter: CSSStyleSheet,
    fontSecondaryMain: CSSStyleSheet,
    fontColorSuccess: CSSStyleSheet,
} = makeStyles(theme => ({
    loginContainer: {
        display: 'flex',
        flexDirection: 'column',
        borderRadius: '0.57rem',
        overflow: 'hidden',
        width: '100%',
    },
    loginFormContainer: {
        padding: '3.79rem',
    },
    loginMessageContainer: {
        marginTop: '2.14rem'
    },
    forgetPasswordFont: {
        textDecoration: 'underline',
    },
    loginFooter: {
        marginTop: '1.71rem',
        height: '4.29rem',
        backgroundColor: theme.palette.grey[50],
        borderRadius: '0 0 0.57rem 0.57rem',
    },
    fontSecondaryMain: {
        color: theme.palette.secondary.main,
    },
    fontColorSuccess: {
        color: theme.palette.success.main,
    },
}));

const LoginForm = () => {

    const appContext = useContext(AppContext)
    const { user, updateUser } = appContext
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const [rememberMe, setRememberMe] = useState(false);
    const [redirectPath, setRedirectPath] = useState(null);
    const [persistRedirectData, setPersistRedirectData] = useState(false);
    const timedOut = URLUtils.getQueryVariable('timeout')
    const loggedOut = URLUtils.getQueryVariable('loggedOut')
    const history = useNavigate()
    const routerLocation = useLocation();
    const globalCancel = () => history(getLandingRedirectPathForUser(user))
    const [safeState, setSafeState] = useState({
        account: null,
        errorMessage: '',
        loggedOut,
        sessionTimedOut: timedOut,
    })

    const classes = useStyles();

    useEffect(() => {
        const defaultPath = new URLSearchParams(window.location.search).get('redirect');
        if (defaultPath) {
            setRedirectPath(defaultPath);
        } else if (routerLocation.state) {
            if (routerLocation.state.redirectTo) {
                setRedirectPath(routerLocation.state.redirectTo);
                if (routerLocation.state?.persistRedirectData) {
                    setPersistRedirectData(true);
                }
            }
            if (routerLocation.state.referrer) {
                // Save in case user has to reset their password
                // This will persist until we manually remove the item
                localStorage.setItem(REDIRECT_REFERRER, routerLocation.state.referrer);
            }
        } else if (localStorage.getItem(REDIRECT_REFERRER)) {
            setRedirectPath(localStorage.getItem(REDIRECT_REFERRER));
        } else {
            setRedirectPath(defaultPath);
        }
    }, [redirectPath, routerLocation]);

    const handleTextChange = (event) => {
      const { name, value } = event.target;

      switch(name) {
        case 'email':
          setEmail(value);
          break;
        case 'password':
          setPassword(value);
          break;
        default:
          break;
      }
    };

    function handleLogin(e) {
        e.preventDefault();
        Busy.set(true);
        let formData = new FormData();
        formData.append('username', email);
        formData.append('password', password);
        formData.append('remember-me', rememberMe);

        $.ajax({
            url: '/api/login',
            data: formData,
            type: 'POST',
            contentType: false,
            cache: false,
            processData: false,
            success: handleSuccess,
            error: handleFailedLogin
        });
    }

    function handleSuccess(loggedInAccount) {
        Busy.set(false);
        let noAccountForUser = 'anonymousUser' === loggedInAccount.username;
        let accountToUse = noAccountForUser ? {} : loggedInAccount;
        globalCancel();
        setSafeState({
            account: accountToUse,
            errorMessage: noAccountForUser ? "No account found for given username" : "",
            loggedOut: false,
            sessionTimedOut: false
        });
        updateUserAndRedirect(accountToUse, redirectPath, persistRedirectData ? { referrerData: routerLocation.state?.redirectData } : undefined);
    }

    function handleFailedLogin(jqXHR) {
        Busy.set(false);
        let errorMessage = jqXHR.responseJSON ? jqXHR.responseJSON.message : "Internal Server Error";
        if (errorMessage && "Bad credentials" === errorMessage.trim()) {
            errorMessage = "Invalid username or password";
        }
        setSafeState({
            errorMessage: errorMessage,
            loggedOut: false,
            sessionTimedOut: false
        });
    }

    const updateUserAndRedirect = (user, redirectPath, redirectData) => {
        updateUser(user, history, redirectPath, redirectData)
        // On successful log in, we no longer need this in localStorage
        localStorage.removeItem(REDIRECT_REFERRER);
        SessionPoller.beginPolling(() => {
            updateUser(undefined)
            history(`${LOGIN_PATH}?timeout=true`)
        })
    }

    return (
        <Paper elevation={2} className={classes.loginContainer}>
            <Grid container direction='column' className={classes.loginFormContainer}>
                <Typography variant='h5' component='h1'>Log In</Typography>
                <form onSubmit={handleLogin} className="ss-login-form">
                    <TextField
                        type='email'
                        InputLabelProps={{
                            shrink: true,
                        }}
                        name={'email'}
                        value={email}
                        onChange={handleTextChange}
                        placeholder='Please enter your mail'
                        className='ss-login-input'
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <img src="https://static.secur.space/icons/Email.png" alt="Icon" className='ss-login-icon-email' />
                                </InputAdornment>
                            ),
                        }}
                        fullWidth
                        variant="standard"
                        label="Email"
                    />
                    <TextField
                        type='password'
                        InputLabelProps={{
                            shrink: true,
                        }}
                        variant="standard"
                        name={'password'}
                        value={password}
                        onChange={handleTextChange}
                        placeholder='Please enter your password'
                        className='ss-login-input password'
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <img src="https://static.secur.space/icons/Password.png" alt="Icon" className='ss-login-icon-password' />
                                </InputAdornment>
                            ),
                        }}
                        fullWidth
                        label="Password"
                    />
                    {
                        safeState.sessionTimedOut ? <Typography variant='body1' component='div' align='center' className={classNames(classes.loginMessageContainer, classes.fontColorSuccess)}>Session timed out. Please login again.</Typography>
                            : safeState.loggedOut ? <Typography variant='body1' component='div' align='center' className={classNames(classes.loginMessageContainer, classes.fontColorSuccess)}>Successfully logged out</Typography>
                                :
                                safeState.errorMessage ?
                                    <Typography variant='body1' component='div' color='error' align='center' className={classes.loginMessageContainer}>{safeState.errorMessage}</Typography>
                                    :
                                    ""
                    }
                    <Grid className='ss-login-forgot-section' container justifyContent='space-between' alignItems='center'>
                        <FormControlLabel
                            className='ss-login-remember-title'
                            control={
                                <Checkbox
                                    color="primary"
                                    inputProps={{ 'aria-label': 'checkbox with default color' }}
                                    value={rememberMe}
                                    onChange={(e) => setRememberMe(e.target.value)}
                                />
                            }
                            label="Remember Me?"
                        />
                        <Typography variant='body2'>
                            <Link
                                to={{ pathname: "/forgot-password" }}
                                className={classNames(classes.fontSecondaryMain, classes.forgetPasswordFont)}
                            >
                                Forgot Password?
                            </Link>
                        </Typography>
                    </Grid>
                    <Button disabled={!email || !password} fullWidth type='submit' variant="contained" color="primary">
                        Log in
                    </Button>
                </form>
            </Grid>
            <Grid container className={classes.loginFooter} justifyContent='center' alignItems='center'>
                <Typography variant="subtitle2" align='center' color="textPrimary" className='w-100'>
                    Don't have an account? <Link to={{ pathname: "/customer-signup" }} className={classes.fontSecondaryMain}>Sign Up</Link>
                </Typography>
            </Grid>
        </Paper>
    );
};

export default LoginForm;
