const headerCells = [
  {
    id: 'transactionNumber',
    label: 'Invoice Number'
  },
  {
    id: 'supplierAmount',
    label: 'Amount'
  },
  {
    id: 'transactionType',
    label: 'Invoice Type'
  },
  {
    id: 'serviceDates',
    label: 'Service Dates'
  },
  {
    id: 'locationName',
    label: 'Location'
  },
  {
    id: 'buyerCompanyName',
    label: 'Buyer'
  },
  {
    id: 'transferType',
    label: 'Type of Charge'
  },
  {
    id: 'numberOfSpaces',
    label: 'Spaces'
  },
  {
    id: 'status',
    label: 'Status'
  },
  {
    id: 'createdOn',
    label: 'Invoice Created Date'
  },
  {
    id: 'paymentCreatedOn',
    label: 'Payment Initiated Date'
  },
  {
    id: 'paymentCompletedOn',
    label: 'Payment Completed Date'
  },
  {
    id: 'payoutCreatedOn',
    label: 'Payout Initiated'
  },
  {
    id: 'payoutCompletedOn',
    label: 'Payout Completed'
  },
  {
    id: 'paymentType',
    label: 'Payment Type'
  },
  {
    id: 'createdOn',
    label: 'Charged Date'
  },
  {
    id: 'bookingNumber',
    label: 'Booking'
  },
  {
    id: 'assetType',
    label: 'Equipment Type'
  },
  {
    id: 'serviceFeeType',
    label: 'Service Charge Type'
  },
];
const sortByFields = [
  { label: "Created On", value: "createdOn" },
  { label: "Invoice Number", value: "transactionNumber" },
  { label: "Invoice Type", value: "transactionType" },
  { label: "Amount", value: "supplierAmount" },
  { label: "Location", value: "locationName" },
  { label: "Buyer", value: "buyerCompanyName" },
  { label: "Asset Type", value: "assetType" },
  { label: "Booking", value: "bookingNumber" }
];
const filterByOptions = [
  {
    label: "Booking Number",
    value: "",
    name: "bookingNumber",
  },
  {
    label: "Invoice Number",
    value: "",
    name: "transactionNumber",
  }
];
const invoiceTypeOptions = [
  { value: '', label: 'All' },
  { value: 'BOOKING_CHARGE', label: 'Booking Payment' },
  { value: 'CANCELLATION_REFUND', label: 'Cancellation Refund' },
  { value: 'OVERAGE_CHARGE', label: 'Overage Payment' },
  { value: 'DATE_ADJUST_CHARGE', label: 'Date Adjust Payment' },
  { value: 'DATE_ADJUST_REFUND', label: 'Date Adjust Refund' },
  { value: 'OVERSTAY_CHARGE', label: 'Overstay Payment' },
  { value: 'ADD_SPACE_CHARGE', label: 'Add Space Charge' },
  { value: 'SPACE_REMOVED_REFUND', label: 'Space Removed Refund' },
  { value: 'DAILY_OVERAGE_CHARGE', label: 'Daily Overage Payment' },
  { value: 'AGS_FEE', label: 'Ags Fee' },
  { value: 'SERVICE_CHARGE', label: 'Service Charge' },
  { value: 'BILLING_CORRECTION_REFUND', label: 'Billing Correction Refund' },
];
const statusOptions = [
  { value: '', label: 'All' },
  { value: 'BOOKING_DECLINED', label: 'Booking Declined' },
  { value: 'CHARGE_ON_HOLD', label: 'Charge On Hold' },
  { value: 'CHARGE_PENDING', label: 'Charge Pending' },
  { value: 'INCOMPLETE', label: 'Incomplete' },
  { value: 'REFUND_REQUESTED', label: 'Refund Requested' },
  { value: 'REFUND_DENIED', label: 'Refund Denied' },
  { value: 'MANUAL_PAYMENT', label: 'Manual Payment' },
  { value: 'NOT_APPROVED', label: 'Approval Pending' },
  { value: 'PAYMENT_FAILED', label: 'Payment Failed' },
  { value: 'PAYMENT_PENDING', label: 'Payment Pending' },
  { value: 'PAYOUT_FAILED', label: 'Payout Failed' },
  { value: 'PAYOUT_ON_HOLD', label: 'Payout On Hold' },
  { value: 'PAYOUT_PENDING', label: 'Payout Pending' },
  { value: 'PAYOUT_READY', label: 'Payout Ready' },
  { value: 'PAYOUT_SUCCEEDED', label: 'Complete' },
  { value: 'UNCOLLECTIBLE', label: 'Uncollectible' },
  { value: 'CANCELLED', label: 'Cancelled' },
  { value: 'DISPUTED', label: 'Disputed' },
];
const filterBySelectOption = [
    {
        label: "Invoice Type",
        value: "",
        name: "transactionType",
        option: invoiceTypeOptions
    },
    {
        label: "Status",
        value: "",
        name: "status",
        option: statusOptions
    },
];

export {
    headerCells,
    sortByFields,
    filterByOptions,
    filterBySelectOption
}