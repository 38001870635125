const headerCells = [
    {
        id: 'checkInDate',
        label: 'Check In'
    },
    {
        id: 'containerNumber',
        label: 'Container'
    },
    {
        id: 'trailerNumber',
        label: 'Trailer'
    },
    {
        id: 'chassisNumber',
        label: 'Chassis'
    },
    {
        id: 'orderNumber',
        label: 'Booking'
    },
    {
        id: 'locationName',
        label: 'Location'
    },
    {
        id: 'chassisLicensePlateNumber',
        label: 'Chassis License Plate'
    },
    {
        id: 'assetSize',
        label: 'Size'
    },
    {
        id: 'sealNumber',
        label: 'Seal'
    },
    {
        id: 'assetType',
        label: 'Type'
    },
    {
        id: 'supplierName',
        label: 'Supplier'
    }
];
const filterByOptions = [
    {
        label: "Booking Number",
        value: "",
        name: "bookingNumber"
    },
    {
        label: "Equipment Number",
        value: "",
        name: "equipmentNumber",
    },
    {
        label: "Driver Name",
        value: "",
        name: "driverName",
    }
];
const groupOption = [
    {
        value: 'checkInDate',
        label: 'Check In Date'
    },
    {
        value: 'locationName',
        label: 'Location'
    },
    {
        value: 'supplierName',
        label: 'Supplier'
    },
    {
        value: 'assetType',
        label: 'Equipment Type'
    },
    {
        value: 'orderNumber',
        label: 'Booking Number'
    },
    {
        value: 'driverFirstName',
        label: 'Driver First Name'
    },
    {
        value: 'driverLastName',
        label: 'Driver Last Name'
    },
    {
        value: 'containerNumber',
        label: 'Container Number'
    },
    {
        value: 'trailerNumber',
        label: 'Trailer Number'
    },
    {
        value: 'chassisNumber',
        label: 'Chassis Number'
    },
    {
        value: 'truckLicensePlateNumber',
        label: 'Truck License Plate Number'
    }
];

const equipmentTypeOption = [
    {
        value: 'All',
        label: "All"
    },
    {
        value: 'CONTAINER_LOADED',
        label: "Container / Chassis (Loaded)"
    },
    {
        value: 'CONTAINER_EMPTY',
        label: "Container / Chassis (Empty)"
    },
    {
        value: 'CHASSIS_ONLY',
        label: "Chassis Only"
    },
    {
        value: 'TRUCK_CONTAINER_LOADED',
        label: "Truck + Container (Loaded)"
    },
    {
        value: 'TRUCK_TRAILER_LOADED',
        label: "Truck + Trailer (Loaded)"
    },
    {
        value: 'TRUCK_CONTAINER_EMPTY',
        label: "Truck + Container / Chassis (Empty)"
    },
    {
        value: 'TRUCK_TRAILER_EMPTY',
        label: "Truck + Trailer (Empty)"
    },
    {
        value: 'TRUCK_ONLY',
        label: "Truck Only"
    },
    {
        value: 'TRUCK_ONLY_25',
        label: "Truck Only 25"
    },
    {
        value: 'TRAILER_LOADED',
        label: "Trailer (Loaded)"
    },
    {
        value: 'TRAILER_EMPTY',
        label: "Trailer (Empty)"
    },
    {
        value: 'REEFER_LOADED_PLUGIN',
        label: "Refrigerated (Loaded) (Plug In)"
    },
    {
        value: 'REEFER_LOADED_NO_PLUGIN',
        label: "Reefer (Loaded) (No Plug In)"
    },
    {
        value: 'TRUCK_REEFER_LOADED_PLUGIN',
        label: "Truck + Reefer (Loaded) (Plug In)"
    },
    {
        value: 'TRUCK_REEFER_LOADED_NO_PLUGIN',
        label: "Truck + Reefer (Loaded) (No Plug In)"
    },
];


const filterBySelectOption = [
    {
        label: "Equipment",
        value: "",
        name: "equipment",
        option: equipmentTypeOption
    },
];


export {
    headerCells,
    filterByOptions,
    groupOption,
    equipmentTypeOption,
    filterBySelectOption
}