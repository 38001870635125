import React, { useContext } from 'react'
import { Route, useNavigate } from "react-router";
import { AppContext } from "../../context/app-context";
import { withAccessControl } from "../ProtectedRoute";
import AccessControlBuilder from "../AccessControlBuilder";

import AdminReports from "../../views/AdminReports";
import AdminAccountsReport from "../../views/AdminAccountsReport";
import AdminBookingsReport from "../../views/AdminBookingsReport";
import AdminLocationsReport from "../../views/AdminLocationsReport";
import AdminPendingCharges from "../../views/AdminPendingCharges";
import AdminReadyForPayoutReport from "../../views/AdminReadyForPayoutReport";
import AdminPayoutsReport from "../../views/AdminPayoutsReport";
import AdminBookedSpaceCalendarReport from "../../views/AdminBookedSpaceCalendarReport";
import LoginAsAccount from "../../views/LoginAsAccount";
import AdminInvoicesReport from "../../components/admin/invoices/AdminInvoicesReport";
import AdminInvoicesReportOld from "../../views/AdminInvoicesReportOld";
import ExternalInvoice from '../../views/errors/ExternalInvoice';

const adminAuthorization = new AccessControlBuilder().allowAdmin().build()

const ProtectedAdminReports = withAccessControl(AdminReports, adminAuthorization)
const ProtectedAdminAccountsReport = withAccessControl(AdminAccountsReport, adminAuthorization)
const ProtectedAdminBookingsReport = withAccessControl(AdminBookingsReport, adminAuthorization)
const ProtectedAdminLocationsReport = withAccessControl(AdminLocationsReport, adminAuthorization)
const ProtectedAdminInvoicesReport = withAccessControl(AdminInvoicesReport, adminAuthorization)
const ProtectedAdminInvoicesReportOld = withAccessControl(AdminInvoicesReportOld, adminAuthorization)
const ProtectedAdminPendingCharges = withAccessControl(AdminPendingCharges, adminAuthorization)
const ProtectedAdminReadyForPayoutReport = withAccessControl(AdminReadyForPayoutReport, adminAuthorization)
const ProtectedAdminPayoutsReport = withAccessControl(AdminPayoutsReport, adminAuthorization)
const ProtectedAdminBookedSpaceCalendarReport = withAccessControl(AdminBookedSpaceCalendarReport, adminAuthorization)
const ProtectedLoginAsAccount = withAccessControl(LoginAsAccount, adminAuthorization)
const ProtectedExternalInvoice = withAccessControl(ExternalInvoice, adminAuthorization)


const AdminContent = () => {
    const appContext = useContext(AppContext)
    const { user, updateUser, logout } = appContext

    const history = useNavigate()

    const updateUserAndRedirect = (user: Object) => {
        updateUser(user, history)
    }

    return [
        <Route path="/admin-reports" key="/admin-reports" element={<ProtectedAdminReports account={user} />
        } >
        </Route>,
        <Route path="/admin-accounts" key="/admin-accounts" element={<ProtectedAdminAccountsReport account={user} handleLogout={logout} />
        } >
        </Route>,
        <Route path="/admin-bookings" key="/admin-bookings" element={<ProtectedAdminBookingsReport account={user} handleLogout={logout} />}>

        </Route>,
        <Route path="/admin-locations" key="/admin-locations" element={<ProtectedAdminLocationsReport account={user} handleLogout={logout} />}>

        </Route>,
        <Route path="/admin-invoices" key="/admin-invoices" element={<ProtectedAdminInvoicesReportOld account={user} handleLogout={logout} />}>

        </Route>,
        <Route path="/admin-invoices-new" key="/admin-invoices-new" element={<ProtectedAdminInvoicesReport account={user} handleLogout={logout} />}>

        </Route>,
        <Route path="/admin-pending-charges" key="/admin-pending-charges" element={<ProtectedAdminPendingCharges account={user} handleLogout={logout} />}>

        </Route>,
        <Route path="/admin-ready-for-payout" key="/admin-ready-for-payout" element={<ProtectedAdminReadyForPayoutReport account={user} handleLogout={logout} />}>

        </Route>,
        <Route path="/admin-payouts" key="/admin-payouts" element={<ProtectedAdminPayoutsReport account={user} handleLogout={logout} />}>

        </Route>,
        <Route path="/admin-booked-space-calendar" key="/admin-booked-space-calendar" element={<ProtectedAdminBookedSpaceCalendarReport account={user} handleLogout={logout} />}>

        </Route>,
        <Route path="/login-as-account" key="/login-as-account" element={<ProtectedLoginAsAccount handleAccountChange={updateUserAndRedirect} />}>

        </Route>,
        <Route path="/invoice/:invoiceNumber" key="/invoice" element={<ProtectedExternalInvoice type="overage" />}>

        </Route>,
    ]
}

export default AdminContent
