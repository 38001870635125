import React, { useContext } from 'react'
import { Route } from "react-router"
import { withAccessControl } from "../ProtectedRoute";
import { UserType, AccountType, SubscriptionType } from "../../components/constants/securspace-constants";
import AccessControlBuilder from "../AccessControlBuilder";

import Approvals from "../../views/Approvals";
import SupplierBookingsReport from "../../views/SupplierBookingsReport";
import SupplierInvoicesReport from "../../views/SupplierInvoicesReport";
import RedesignSupplierInvoicesReport from "../../views/invoices/SupplierInvoicesReport";
import SupplierReadyForPayoutReport from "../../views/SupplierReadyForPayoutReport";
import SupplierPayoutsReport from "../../views/SupplierPayoutsReport";
import SupplierBookedSpaceCalendarReport from "../../views/SupplierBookedSpaceCalendarReport";
import GmsUpgrade from "../../components/gms/GmsUpgrade";
import { AppContext } from "../../context/app-context";
import ChargeServiceFee from "../../views/servicefees/ChargeServiceFee";
import PartnerSubscriptions from '../../views/PartnerSubscriptions';
import PartnerSubscriptionInvoicesReport from '../../views/PartnerSubscriptionInvoicesReport';
import {Navigate} from "react-router-dom";
import {useFlags} from "launchdarkly-react-client-sdk";
import SupplierSpaceUsage from "../../views/SupplierSpaceUsage";
import SupplierInventoryCorrections from '../../views/inventory-correction/SupplierInventoryCorrections';
import SupplierBooking from '../../views/supplier-booking/SupplierBooking';
import SupplierBookingApprovals from '../../views/supplier-bookings-approvals/SupplierBookingApprovals';

const allSupplierOwnerAuthorization = new AccessControlBuilder().allowAllSupplierOwner().build()
const gmsProGateManagersAuthorization = new AccessControlBuilder().allowGmsProGateManagers().build()
const gateManagerAuthorization = new AccessControlBuilder().allowGateManager().build()
const gmsProOwnerAuthorization = new AccessControlBuilder().allowGmsProOwner().build()

const canGateManagersViewSupplierBookings = (user: { allowsGateManagersToViewBookings: boolean }) => {
    return user && user.allowsGateManagersToViewBookings
}

const modifiedGateManagerAuthorization = {
    ...gateManagerAuthorization,
    additionalValidation: canGateManagersViewSupplierBookings
}

const gmsUpgradeAuthorization = new AccessControlBuilder()
    .allowAccountType(AccountType.SUPPLIER)
    .allowUserType(UserType.GATE_CLERK)
    .allowUserType(UserType.GATE_MANAGER)
    .allowSubscriptionType(SubscriptionType.MARKETPLACE_ONLY)
    .build()

const ProtectedApprovals = withAccessControl(Approvals, allSupplierOwnerAuthorization, gateManagerAuthorization)
const ProtectedSupplierBookingsReport = withAccessControl(SupplierBookingsReport, allSupplierOwnerAuthorization, modifiedGateManagerAuthorization)
const ProtectedRedesignSupplierBooking = withAccessControl(SupplierBooking, allSupplierOwnerAuthorization, modifiedGateManagerAuthorization)
const ProtectedSupplierInvoicesReport = withAccessControl(SupplierInvoicesReport, allSupplierOwnerAuthorization)
const ProtectedRedesignSupplierInvoicesReport = withAccessControl(RedesignSupplierInvoicesReport, allSupplierOwnerAuthorization)
const ProtectedSupplierReadyForPayoutReport = withAccessControl(SupplierReadyForPayoutReport, allSupplierOwnerAuthorization)
const ProtectedSupplierPayoutsReport = withAccessControl(SupplierPayoutsReport, allSupplierOwnerAuthorization)
const ProtectedPartnerSubscriptionsReport = withAccessControl(PartnerSubscriptions, gmsProOwnerAuthorization);
const ProtectedPartnerSubscriptionsInvoicesReport = withAccessControl(PartnerSubscriptionInvoicesReport, gmsProOwnerAuthorization);
const ProtectedSupplierBookedSpaceCalendarReport = withAccessControl(SupplierBookedSpaceCalendarReport, gmsProOwnerAuthorization)
const ProtectedSupplierInventoryCorrections = withAccessControl(SupplierInventoryCorrections, gmsProGateManagersAuthorization);
const ProtectedGmsUpgrade = withAccessControl(GmsUpgrade, gmsUpgradeAuthorization)
const ProtectedChargeServiceFee = withAccessControl(ChargeServiceFee, allSupplierOwnerAuthorization)
const ProtectedRedesignSupplierSpaceUsage = withAccessControl(SupplierSpaceUsage, gmsProOwnerAuthorization);
const ProtectedRedesignSupplierBookingApprovals = withAccessControl(SupplierBookingApprovals, allSupplierOwnerAuthorization, gateManagerAuthorization);

const SupplierContent = (readSupplierPendingBooking) => {

    const appContext = useContext(AppContext);
    const { user, logout } = appContext;
    const {  supplierSpaceUsageRedesign, inventoryCorrection, bookingApprovalRedesign, supplierBookingsNavRedesign, supplierInvoicesRedesign } = useFlags();

    return [
        <Route path="/approvals" key="/approvals" element={
            bookingApprovalRedesign ?  <Navigate to={'/bookings/approvals'} /> :
        <ProtectedApprovals account={user} handleLogout={logout} readPendingBooking={readSupplierPendingBooking} />

        }>
        </Route>,
        <Route path="/supplier-bookings" key="/supplier-bookings" element={
            supplierBookingsNavRedesign ? <Navigate to={'/bookings/supplier-bookings'} /> :
        <ProtectedSupplierBookingsReport account={user} handleLogout={logout} />
        }>

        </Route>,
        <Route path="/supplier-invoices" key="/supplier-invoices" element={supplierInvoicesRedesign ? <Navigate to={'/finances/supplier-invoices'} /> :<ProtectedSupplierInvoicesReport account={user} handleLogout={logout} />}>

        </Route>,
        <Route path="/ready-for-payout" key="/ready-for-payout" element={<ProtectedSupplierReadyForPayoutReport account={user} handleLogout={logout} />}>

        </Route>,
        <Route path="/supplier-payouts" key="/supplier-payouts" element={<ProtectedSupplierPayoutsReport account={user} handleLogout={logout} />}>

        </Route>,
        <Route exact path="/subscriptions" key="/subscriptions" element={<ProtectedPartnerSubscriptionsReport account={user} handleLogout={logout} />}>

        </Route>,
        <Route path="/subscriptions/:subscriptionId" key="/subscriptions/subscriptionId" element={<ProtectedPartnerSubscriptionsInvoicesReport account={user} handleLogout={logout} />}>

        </Route>,
        <Route path="/supplier-booked-space-calendar" key="/supplier-booked-space-calendar" element={
            supplierSpaceUsageRedesign ?
            <Navigate to={'/bookings/space-usage'} />
            :
            <ProtectedSupplierBookedSpaceCalendarReport account={user} handleLogout={logout} />}>
        </Route>,
        <Route path="/inventory-corrections" key="/inventory-corrections" element={
            inventoryCorrection ? <ProtectedSupplierInventoryCorrections account={user} handleLogout={logout} /> : <Navigate to={'/'}/>
        }>
        </Route>,
        <Route path="/gms-upgrade" key="/gms-upgrade" element={<ProtectedGmsUpgrade />}>

        </Route>,
        <Route path="/booking/:bookingId/charge-service-fee" key="booking/bookingId/charge-service-fee" element={<ProtectedChargeServiceFee account={user} handleLogout={logout} />}>
        </Route>,
        <Route path={"/bookings/space-usage"} key={"/bookings/space-usage"} element={<ProtectedRedesignSupplierSpaceUsage account={user} handleLogout={logout} />}/>,
        <Route path={"/bookings/approvals"} key={"/bookings/approvals"} element={<ProtectedRedesignSupplierBookingApprovals account={user} handleLogout={logout} />}/>,
        <Route path={"/finances/supplier-invoices"} key={"/finances/supplier-invoices"} element={<ProtectedRedesignSupplierInvoicesReport account={user} handleLogout={logout} />}/>,
        <Route path={"/bookings/supplier-bookings"} key={"/bookings/supplier-bookings"} element={<ProtectedRedesignSupplierBooking account={user} handleLogout={logout} />}/>
    ];
};

export default SupplierContent;
