import React, {useMemo, useRef, useCallback} from "react";
import { Grid } from "@mui/material";
import { SortFilter } from '@securspace/securspace-ui-kit';
import { sortByFields } from "../formatSpaceUsage.js";
import { getAllLocationsByBuyer } from "../../location/requests/location-requests";
import useGetRequestBody from "../../../hooks/useGetRequestBody";

import {withSnackbar} from "../../hocs/withSnackbar";

const BuyerSpaceUsageFilters = ({buyerAccountId, 
  setSortBy, 
  setSelectedLocation, 
  setStartDate, 
  setEndDate, 
  setBookingNumber, 
  setInvoiceNumber, 
  snackbarShowMessage, 
  setSortDir, 
  savedStartDate, 
  savedEndDate,
  savedLocation,
  savedBookingNumber,
  savedInvoiceNumber,
  savedSortBy  
}) => {

  const locations = useGetRequestBody(getAllLocationsByBuyer, [buyerAccountId], snackbarShowMessage);

  const locationFromStorage = JSON.parse(window.sessionStorage.getItem("location"));
  const defaultLocation = locationFromStorage || savedLocation;
  const initialRender = useRef(true);

  const parseFilters = (filterArray) => {
    const bookingNumber = filterArray.find(item => item.name === "bookingNumber")?.value;
    const invoiceNumber = filterArray.find(item => item.name === "overageInvoiceNumber")?.value;
    setBookingNumber(bookingNumber);
    setInvoiceNumber(invoiceNumber);
  }

  const handleCallback = useCallback((e) => {
    if (!initialRender.current) {
        setSelectedLocation(e.location);
        setSortBy(e.sortBy);
        setStartDate(e.startDate);
        setEndDate(e.endDate);
        parseFilters(e.filters)
        setSortDir(e.sortDir)
    } else {
        initialRender.current = false;
    }
  }, [setStartDate, setEndDate, setSortDir, setSortBy, setSelectedLocation, parseFilters]);


  const filterByOptions = useMemo(() => {
    return [
      {name: "bookingNumber", label: "Booking Number", value: savedBookingNumber},
      {name: "overageInvoiceNumber", label: "Invoice Number", value: savedInvoiceNumber}
    ];
  }, [savedBookingNumber, savedInvoiceNumber]);

  return (
    <Grid item container spacing={2} direction={"column"}>
      <SortFilter
        filterCallback={handleCallback}
        sortByOption={sortByFields}
        locations={locations}
        filterByOption={filterByOptions}
        defaultStartDate={savedStartDate}
        defaultEndDate={savedEndDate}
        defaultSortBy={savedSortBy}
        defaultLocation={defaultLocation}
        sortDir={"asc"}
      />
    </Grid>
  )
};

export default withSnackbar(BuyerSpaceUsageFilters);
