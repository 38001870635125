import React from "react";
import {GridCard} from "@securspace/securspace-ui-kit";
import {withSnackbar} from "../hocs/withSnackbar";
import {formatSpaceUsageItem} from "./formatSpaceUsage";
import {Inventory, Reorder} from "@mui/icons-material";
import {getBuyerOverageDailyReportByDate} from "../../requests/inventory-requests";
import moment from "moment";

const BuyerSpaceUsageCard = ({spaceUsageItem, account, snackbarShowMessage, handleViewInventory}) => {

  const formattedItem = formatSpaceUsageItem(spaceUsageItem);
  const {date, overageInvoices, location} = formattedItem;
  const {locationId} = spaceUsageItem;
  const {baseUrl, companyName, id} = account;
  const formattedDate = moment(date).format('YYYY-MM-DD');

  const formatForDocName = (string) => string.split(" ").join("_").split("/").join("_").toLowerCase();
  const links = {"overageInvoices": baseUrl + '/api/invoices-by-invoice-number'};

  const actionItems = [
    {
      icon: <Inventory color={'secondary'}/>,
      label: 'Download Inventory Log',
      onClick: () => {
        getBuyerOverageDailyReportByDate(
          baseUrl,
          locationId,
          id,
          formattedDate
        ).then((response) => {
          const {text} = response;
          if (text) {
            const fileName =
              `${formatForDocName(companyName)}_space_usage_${formatForDocName(location)}_${formatForDocName(formattedDate)}.csv`;
            const blob = new Blob([text], {type: 'text/csv'});
            const doc = document.createElement("a");
            doc.download = fileName;
            doc.href = window.URL.createObjectURL(blob);
            const clickEvt = new MouseEvent('click', {
              view: window,
              bubbles: true,
              cancelable: true,
            })
            doc.dispatchEvent(clickEvt);
            doc.remove();
          }
        }).catch(() => {
          snackbarShowMessage(`Error downloading inventory for ${date}.`, 'error', 15000);
        });
      }
    },
    {
      icon: <Reorder color={'secondary'}/>,
      label: 'View Daily Activity',
      onClick: () => handleViewInventory(spaceUsageItem)
    }
  ];

  return <GridCard
    data={formattedItem}
    hasAction={true}
    actionItems={actionItems}
    links={overageInvoices ? links : null}
    styles={{ mb:2, width:'100%'}}
    variant="primary"
  />
}

export default withSnackbar(BuyerSpaceUsageCard);
