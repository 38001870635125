import React, { Component } from 'react'
import '../css/views/signUp.css'
import '../css/theme/mainContent.css';
import '../css/theme/forms.css';
import '../css/theme/forms-block.css';
import '../css/theme/buttons.css';
import '../css/views/accountConfirmation.css'
import Error from "../components/Error";
import request from "superagent"
import PropTypes from 'prop-types'
import CheckBox from "../components/CheckBox";
import { GlobalModalContext } from "../context/global-modal-context";

export default class AccountConfirmation extends Component {

    static propTypes = {
        account: PropTypes.object.isRequired,
        onAccountConfirmation: PropTypes.func.isRequired,
        closeModal: PropTypes.func
    }

    static contextType = GlobalModalContext

    constructor(props) {
        super(props);

        const {
            companyName,
            firstName,
            lastName,
            phoneNumber,
            email
        } = this.props.account || {}

        this.state = {
            companyName,
            firstName,
            lastName,
            phoneNumber,
            email,
            password: "",
            confirmPassword: ""
        }
    }

    handleChange = (event) => {
        let name = event.target.name
        let value = event.target.value
        this.setState({[name]: value})
    }

    submit = () => {
        const { id } = this.props.account;

        let errorMessage

        if(!this.state.agreementAccepted) {
            errorMessage = "Please accept the Terms of Service"
        }


        if(errorMessage) {
            this.setState({errorMessage})
            return
        }

        let url = `/api/account/confirm-tos/${id}`;

        request
            .post(url)
            .send({ ...this.state })
            .then(this.handleSuccessfulConfirmation, this.handleFailedConfimation)
    }

    handleSuccessfulConfirmation = () => {
        if(this.props.closeModal) {
            this.props.closeModal();
        }

        this.props.onAccountConfirmation();
    }

    handleFailedConfimation = ({ response }) => {
        const { body } = response || {}

        if(response) {
            this.setState({errorMessage: body.message})
        } else {
            this.setState({errorMessage: "There was an error completing your request possibly due to a network connectivity issue"})
        }
    }

    render() {

        const globalModalContext = this.context
        const { showTermsModal, showPrivacyModal } = globalModalContext


        return (
            <div className={`account-confirmation ${this.props.className}`}>
                <div className="popup-header account-confirmation-header">
                    <h1>SecūrSpace Terms and Conditions Acknowledgement</h1>
                </div>
                <div className="login-form account-confirmation-form">

                    <div className="account-confirmation-form-welcome-text">
                        We have made updates to our Privacy Policy and Terms and Conditions. Please review the changes
                        before continuing.
                    </div>

                    <fieldset>
                        <div className="agreement-container">
                            <div className="agreement-checkbox-container">
                                <CheckBox checked={this.state.agreementAccepted}
                                          onCheck={(value) => this.setState({agreementAccepted: value})}
                                />
                            </div>
                            <div className="signup-terms">
                                I agree to the SecūrSpace &nbsp;
                                <span className="pointer" onClick={() => showTermsModal(2)}>Terms of Use</span>
                                &nbsp;and&nbsp;
                                <span className="pointer" onClick={() => showPrivacyModal(2)}>Privacy Policy</span>,
                                as well as our partner <a
                                href="https://www.dwolla.com/legal/tos/"
                                target="_blank"
                                rel="noopener noreferrer">Dwolla's Terms of Service</a> and <a href="https://www.dwolla.com/legal/privacy/"
                                                                                               target="_blank"
                                                                                               rel="noopener noreferrer">Privacy Policy</a>
                            </div>
                        </div>
                    </fieldset>

                    {
                        this.state.errorMessage &&
                        <fieldset>
                            <Error>{this.state.errorMessage}</Error>
                        </fieldset>
                    }

                    <fieldset className="account-confirmation-form-submit-btn">
                        <div className="orange-button ss-action-button" onClick={this.submit}>
                            Agree and continue
                        </div>
                    </fieldset>
                </div>
            </div>
        )
    }
}
