import React from 'react'
import { Route } from "react-router";
import ContextualSearch from "../../components/search/ContextualSearch";
import About from "../../views/about/About";
import Contact from "../../views/Contact";
import LandingPage from "../../views/LandingPage";
import Landing from "../../views/Landing";
import BookingApproval from "../../views/BookingApproval";
import ExternalLanding from "../../views/ExternalLanding";
import UserBasedLandingRedirect from "../UserBasedLandingRedirect";
import Logout from "../../components/Logout";
import LocationProfile from '../../views/location/profile/LocationProfile';
import Login from '../../views/login/Login';
import ChangePassword from '../../views/change-password/ChangePassword';
import ForgotPassword from '../../views/forgot-password/ForgotPassword';
import BlogDetails from '../../views/blog-details/BlogDetails';
import Blog from '../../views/blog/Blog';
import ContextualSignUp from '../../views/signup/ContextualSignUp';
import { AccountType } from '../../components/constants/securspace-constants';
import PolicyDocument from '../../views/policyDocument/PolicyDocument';
import { useFlags } from 'launchdarkly-react-client-sdk';
import HubspotBlogPost from "../../views/HubspotBlogPost";
import ExclusiveWebinar from '../../views/ExclusiveWebinar';
import HubSpotResourcesBuyer from "../../views/resources/HubSpotResourcesBuyer";
import HubSpotResourcesSupplier from "../../views/resources/HubSpotResourcesSupplier";

const LandingContent = (readPendingBooking) => {
    const { policyDocumentsRedesign, blogRedesign } = useFlags();

    return [
        <Route path="/gms-landing" key="/gms-landing" element={<ExternalLanding
            url="https://launch.secur.space/gmslanding"
            height="100%"
        />} >

        </Route>,
        <Route path="/search" key="/search" element={<ContextualSearch readPendingBooking={readPendingBooking} />}>

        </Route>,
        <Route path="/location-profile/:locationId" key="/location-profile/:locationId" element={<LocationProfile />}>

        </Route>,
        <Route path="/customer-signup" key="/customer-signup" element={<ContextualSignUp type={AccountType.BUYER} />}>

        </Route>,
        <Route path="/partner-signup" key="/partner-signup" element={<ContextualSignUp type={AccountType.SUPPLIER} />}>

        </Route>,
        <Route path="/login" key="/login" element={<Login />}>

        </Route>,
        <Route path="/forgot-password" key="/forgot-password" element={<ForgotPassword />}>

        </Route>,
        <Route path="/about" key="/about" element={<About />}>

        </Route>,
        <Route path="/contact" key="/contact" element={<Contact />}>
        </Route>,
        <Route path="/exclusive-webinar-access-expert-insights-strategies" key="/exclusive-webinar-access-expert-insights-strategies" element={<ExclusiveWebinar />}>
        </Route>,
        <Route path="/faq" key="/faq" element={<ExternalLanding
            url="https://launch.secur.space/securspace-faqs"
            height="100%"
        />}>

        </Route>,

        <Route path="/blogs" key="/blogs" element={<Blog />}>

        </Route>,

        <Route path="/blog/:postId/:title?" key='/blog' element={blogRedesign ? <BlogDetails /> : <HubspotBlogPost />}>

        </Route>,
        <Route path="/resources-buyer" key="/resources-buyer" element={<HubSpotResourcesBuyer />}>

        </Route>,
        <Route path="/resources-supplier" key="/resources-supplier" element={<HubSpotResourcesSupplier />}>

        </Route>,
        <Route path="/change-password" key="/change-password" element={<ChangePassword isNewUser={false} />}>

        </Route>,
        <Route path="/set-password" key="/set-password" element={<ChangePassword isNewUser={true} />}>

        </Route>,
        <Route path="/landing/:id" key="/landing/:id" element={<LandingPage />}>

        </Route>,
        <Route path="/booking-approval" key="/booking-approval" element={<BookingApproval />}>

        </Route>,
        <Route path="/logout" key="/logout" element={<Logout />}>

        </Route>,
        <Route path="/terms-of-use/:versionNumber?" key="/terms-of-use" element={policyDocumentsRedesign && <PolicyDocument type="terms" />}>

        </Route>,
        <Route path="/privacy-policy/:versionNumber?" key="/privacy-policy" element={policyDocumentsRedesign && <PolicyDocument type="privacy-policy" />}>

        </Route>,
        <Route path="/" exact key="/" element={<Landing />} >

        </Route>,
        <Route path="*" key="*" element={<UserBasedLandingRedirect />} >

        </Route>,
    ]
}

export default LandingContent
