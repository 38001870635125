import React from "react";
import { Button, Grid, Typography } from "@mui/material";
import { makeStyles } from '@mui/styles';
import { AccountType } from "../../../components/constants/securspace-constants";
import { Link } from "react-router-dom";
import classNames from "classnames";

const USER_BASED_REDIRECT_PATH = Object.freeze({
    [AccountType.SUPPLIER]: '/partner-signup',
    [AccountType.BUYER]: '/customer-signup',
});

const useStyles: (theme) => {
    fontWhite: CSSStyleSheet,
    switchAccountTypeTitle: CSSStyleSheet,
    learnMoreText: CSSStyleSheet,
  } = makeStyles(theme => ({
    fontWhite: {
        color: theme.palette.primary.contrastText,
    },
    switchAccountTypeTitle: {
        fontFamily: 'Satoshi-Variable',
        fontWeight: 700,
        fontSize: '1.43rem',
        lineHeight: '1.71rem',
    },
    learnMoreText: {
        fontFamily: 'Inter',
        fontWeight: 500,
        fontSize: '0.93rem',
        lineHeight: '169%',
        letterSpacing: '0.03rem',
        color: theme.palette.secondary.contrastText
    }
}));

export const SwitchToPartnerButton = () => {
    const classes = useStyles();
    
    const routerLink = (React.forwardRef((props, ref) => {
        return <Link ref={ref} to={USER_BASED_REDIRECT_PATH[AccountType.SUPPLIER]} {...props}/>;
    }));

    return (
        <Button component={routerLink}  className="ss-switch-account-type-button">
            <Grid item className={classNames("ss-switch-account-type-button-content", classes.fontWhite)}>
                <Grid item>
                    <Typography component='h6' className={(classNames(classes.switchAccountTypeTitle, classes.fontWhite))}>Sign up as a Supplier</Typography>
                    <Typography variant="subtitle1">List your available parking and storage space</Typography>
                </Grid>
                <Typography variant="subtitle1" className={classes.learnMoreText}>LEARN MORE</Typography>
            </Grid>
        </Button>
    );
};

export const SwitchToCustomerButton = () => {
    const classes = useStyles();

    const routerLink = (React.forwardRef((props, ref) => {
        return <Link ref={ref} to={USER_BASED_REDIRECT_PATH[AccountType.BUYER]} {...props}/>;
    }));

    return (
        <Button component={routerLink} className="ss-switch-account-type-button">
            <Grid item className={classNames("ss-switch-account-type-button-content", classes.fontWhite)}>
                <Grid item>
                    <Typography component='h6' className={classes.switchAccountTypeTitle}>Sign up as a Buyer</Typography>
                    <Typography variant="subtitle1">Reserve available parking and storage space</Typography>
                </Grid>
                <Typography variant="subtitle1" className={classes.learnMoreText}>LEARN MORE</Typography>
            </Grid>
        </Button>
    );
};