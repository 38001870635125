import React, { useState, useEffect } from 'react'
import { Typography, Grid } from '@mui/material';
import moment from 'moment';
import { Pagination, formatFrequency } from '@securspace/securspace-ui-kit'
import SecurSpaceContainer from '../../components/common/SecurSpaceContainer'
import useStyles from '../../components/supplier-booking/styles';
import { SupplierBookingFilter, SupplierBookingList, SupplierBookingTitle } from '../../components/supplier-booking';
import { withSnackbar } from '../../components/hocs/withSnackbar';
import { getErrorMessageForStandardResponse } from '../../util/NetworkErrorUtil';
import { getSupplierBooking } from '../../components/supplier-booking/requests/supplier-booking-rquests';
import { formatCurrencyValue } from '../../util/PaymentUtils';
import { BookingStatus } from '../../components/constants/securspace-constants';
import { scrollToTop } from '../../util/BookingUtil';


export const shouldShowEndDate = (data, bookingStatusLabel) => {
    return (
        data.frequency === 'RECURRING' &&
        data.endDateAdjusted !== true &&
        (data.active === true || data.status === bookingStatusLabel.PENDING || data.status === bookingStatusLabel.INCOMPLETE)
    );
};

export function transformBooking(booking) {
    const commonProperties = {
        initialSupplierPayoutAmount: formatCurrencyValue(booking.initialCharge, true),
        recurringSupplierPayoutAmount: formatCurrencyValue(booking.recurringSupplierPayoutAmount, true),
        frequencyValue: formatFrequency(booking.frequency, booking.durationType),
        startDateValue: moment(booking.startDate).format("L h:mm a").toUpperCase(),
        createdOn: moment(booking.createdOn).format("L h:mm a").toUpperCase(),
        supplierCompanyName: booking.supplierCompanyName ? booking.supplierCompanyName : 'N/A',
        active: booking.active ? 'Active' : 'Not Active',
        brokered: booking.brokered ? 'True' : 'False',
        endDateValue: shouldShowEndDate(booking, BookingStatus) ? 'Until cancelled' : `${moment(booking.endDate).format("L h:mm a").toUpperCase() || "N/A"}`,
        rate: booking.brokered ? 'N/A' : formatCurrencyValue(booking.rate) + (booking.durationType === 'WEEKLY' ? ' /week' : booking.durationType === 'MONTHLY' ? ' /month' : ' /day'),
    };

    return {
        ...booking,
        ...commonProperties
    };
};

const SupplierBooking = ({
    account,
    snackbarShowMessage
}) => {

    const classes = useStyles();
    const [supplierBookings, setSupplierBookings] = useState([]);
    const [loading, setLoading] = useState(false)
    const [page, setPage] = useState(0);
    const [size, setSize] = useState(50);
    const [totalNumberResults, setTotalNumberResults] = useState(0);
    const [selectedLocation, setSelectedLocation] = useState();
    const [startDate, setStartDate] = useState('');
    const [filter, setFilter] = useState('');
    const [endDate, setEndDate] = useState('');
    const [sortBy, setSortBy] = useState('');
    const [equipmentType, setEquipmentType] = useState('');
    const [status, setStatus] = useState('');
    const [active, setActive] = useState(true);
    const [brokered, setBrokered] = useState(false);
    const [frequency, setFrequency] = useState('');
    const [sortDir, setSortDir] = useState('');

    const handlePageChange = (_, selectedPage) => {
        setPage(selectedPage)
    };

    const handleSizeChange = (event) => {
        setSize(event.target.value)
    };

    useEffect(() => {
        const { id } = account;

        if (id) {
            setLoading(true)
            const requestParams = {
                supplierAccountId: id,
                page,
                size,
                startDate: startDate,
                endDate: endDate,
                sortBy,
                sortDir,
                locationId: selectedLocation?.id,
                buyerName: filter,
                equipmentType,
                status,
                active,
                brokered,
                frequency
            }

            getSupplierBooking(requestParams)
                .then(({ body }) => {
                    setTotalNumberResults(body.count);
                    const data = body.content?.map((booking) => {
                        return transformBooking(booking);
                    });
                    setSupplierBookings(data);
                    setLoading(false)
                    scrollToTop()
                })
                .catch(err => {
                    setLoading(false)
                    snackbarShowMessage(getErrorMessageForStandardResponse(err), "error", 15000)
                });
        }

    }, [account, page, size, startDate, endDate, filter, selectedLocation, sortBy, equipmentType, status, active, brokered, frequency, sortDir]);


    return (
        <SecurSpaceContainer className={classes.mainContainer}>
            <Typography mb={4} variant='h5' component='h1'>Bookings</Typography>
            <SupplierBookingFilter
                supplierAccountId={account.id}
                setSelectedLocation={setSelectedLocation}
                setStartDate={setStartDate}
                setEndDate={setEndDate}
                setSortBy={setSortBy}
                setSortDir={setSortDir}
                setFilter={setFilter}
                setEquipmentType={setEquipmentType}
                setStatus={setStatus}
                setActive={setActive}
                setBrokered={setBrokered}
                setFrequency={setFrequency}
            />
            {
                supplierBookings?.length > 0 && <SupplierBookingTitle
                    title={selectedLocation?.locationName || 'All'}
                    count={supplierBookings?.length}
                    supplierBookings={supplierBookings}
                />
            }
            <SupplierBookingList
                filter={filter}
                supplierBookings={supplierBookings}
                loading={loading}
                locationId={selectedLocation?.id || ''}
                sortBy={sortBy}
            />
            {
                supplierBookings?.length > 0 && <Grid item>
                    <Pagination
                        count={totalNumberResults}
                        rowsPerPage={size}
                        page={page}
                        onPageChange={handlePageChange}
                        onRowsPerPageChange={handleSizeChange}
                        variant="tablePagination"
                    />
                </Grid>
            }
        </SecurSpaceContainer>
    )
}

export default withSnackbar(SupplierBooking)
