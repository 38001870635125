import { equipmentTypeOption } from "../buyer-inventory/data";

const sortByFields = [
    {
        label: "Booking Number",
        value: "orderNumber"
    },
    {
        label: "Customer",
        value: "buyerCompanyName"
    },
    {
        label: "Start Date",
        value: "startDate",
    },
    {
        label: "End Date",
        value: "endDate",
    },
    {
        label: "Number of Spaces",
        value: "numberOfSpaces"
    },
    {
        label: "Location",
        value: "locationName"
    },
    {
        label: "Brokered",
        value: "brokered",
    },
    {
        label: "Frequency",
        value: "frequency",
    },
    {
        label: "Equipment Type",
        value: "assetType"
    },
    {
        label: "Initial/Total Charge",
        value: "initialPaymentAmount",
    },
    {
        label: "Recurring Charge",
        value: "recurringPaymentAmount",
    },
    {
        label: "Booking Rate",
        value: "pricePerDay",
    },
    {
        label: "Status",
        value: "status"
    },
    {
        label: "Active",
        value: "active",
    },
    {
        label: "Created On",
        value: "creationDate",
    }
];
const filterByOptions = [
    {
        label: "Buyer Name",
        value: "",
        name: "buyerName",
    },
];

const filterBySelectOption = [
    {
        label: "Equipment Type",
        value: "",
        name: "equipmentType",
        option: equipmentTypeOption
    },
    {
        label: "Status",
        name: "status",
        value: "",
        option: [
            {label: 'All', value: ''},
            {
                label:'Incomplete',
                value:'INCOMPLETE'
            },
            {
                label:'Pending',
                value:'PENDING'
            },
            {
                label:'Pending-Verification Required',
                value:'PENDING_VERIFICATION_REQUIRED'
            },
            {
                label:'Approved',
                value:'APPROVED'
            },
            {
                label:'Approved-Adjusted',
                value:'APPROVED_ADJUSTED'
            },
            {
                label:'Declined',
                value:'DECLINED'
            },
            {
                label:'Cancelled',
                value:'CANCELLED'
            },
            {
                label:'Manual-Payment',
                value:'MANUAL_PAYMENT'
            },
            {
                label:'Error',
                value:'ERROR'
            },
            {
                label:'Processing-ACH-Payment',
                value:'PROCESSING_ACH_PAYMENT'
            },
            {
                label:'Processing-ACH-Payment-Adjusted',
                value:'PROCESSING_DATE_ADJUST_PENDING_ACH_PAYMENT'
            },
            {
                label:'Processing-ACH-Refund-Adjusted',
                value:'PROCESSING_DATE_ADJUST_PENDING_ACH_REFUND'
            },
            {
                label:'Payment Declined',
                value:'PAYMENT_DECLINED'
            },
            {
                label:'Payment Declined-Adjusted',
                value:'PAYMENT_DECLINED_ADJUSTED'
            },
            {
                label:'Refund Declined-Adjusted',
                value:'REFUND_DECLINED_ADJUSTED'
            },
        ]
    },
    {
        label: "Active",
        name: "active",
        value: "",
        option: [
            {label: 'All', value: ''},
            {
                label: 'Active',
                value: 'true'
            },
            {
                label: 'Not Active',
                value: 'false'
            }
        ]
    },
    {
        label: "Brokered",
        value: "",
        name: "brokered",
        option: [
            {label: 'All', value: ''},
            {
                label: 'False',
                value: 'false'
            },
            {
                label: 'True',
                value: 'true'
            }
        ]
    },
    {
        label: "Frequency",
        value: "",
        name: "frequency",
        option: [
            {label: 'All', value: ''},
            {
                label: 'One-Time',
                value: 'ONE_TIME'
            },
            {
                label: 'Recurring',
                value: 'RECURRING'
            }
        ]
    }
]

const headerCells = [
    { id: 'orderNumber', label: 'BOOKING NUMBER' },
    { id: 'buyerCompanyName', label: 'Customer' },
    { id: 'startDateValue', label: 'Start Date' },
    { id: 'endDateValue', label: 'End Date' },
    { id: 'numberOfSpaces', label: 'Number of Spaces' },
    { id: 'locationName', label: 'Location' },
    { id: 'brokered', label: 'Brokered' },
    { id: 'frequencyValue', label: 'Frequency' },
    { id: 'assetType', label: 'Equipment Type' },
    { id: 'initialSupplierPayoutAmount', label: 'Initial/Total Charge' },
    { id: 'recurringSupplierPayoutAmount', label: 'Recurring Charge' },
    { id: 'rate', label: 'Booking Rate' },
    { id: 'status', label: 'STATUS' },
    { id: 'active', label: 'Active' },
    { id: 'createdOn', label: 'Created On' },
];

export {
    sortByFields,
    filterByOptions,
    headerCells,
    filterBySelectOption
}