import React, {useMemo} from 'react';
import SpaceUsageFilterForm from "./SpaceUsageFilterForm";
import useUrlQueryParams from "../../../hooks/useUrlQueryParams";

const SpaceUsageFilterWithQueryParams = (props: {
  supplierAccountId: string,
  onChange: (event) => void,
  defaultStartDate: string,
  defaultEndDate: string
}) => {
  const { supplierAccountId, onChange, defaultStartDate, defaultEndDate } = props;
  // Working with an uncontrolled component here, so we need to pass the initial value in filterByOptions
  const {overageInvoiceNumber} = useUrlQueryParams();
  const filterByOptions = useMemo(() => {
    return [
      {name: "bookingNumber", label: "Booking Number", value: ""},
      {name: "overageInvoiceNumber", label: "Overage Invoice Number", value: overageInvoiceNumber || ""}
    ];
  }, [overageInvoiceNumber]);

  return (
    <SpaceUsageFilterForm
      supplierAccountId={supplierAccountId}
      defaultEndDate={defaultEndDate}
      defaultStartDate={defaultStartDate}
      filterByOptions={filterByOptions}
      onChange={onChange}
    />
  );
};

export default SpaceUsageFilterWithQueryParams;