import React from 'react';
import {ClassNameMap} from "@mui/styles";
import {Button} from "@mui/material";
import request from "superagent";
import {downloadCSVFile} from "../../../util/FileUtils";
import logout from "../../Logout";
import DownloadCsvIcon from "../../icons/DownloadCsvIcon";

const DownloadCSVButton = (props: {
    title?: string,
    reportUrl: string,
    onError?: (event) => void,
    className?: ClassNameMap
}) => {
    const {title, reportUrl, onError, className} = props;
    const handleClick = async () => {
        try {
            const {text, headers} = await request.get(reportUrl);
            const rawFileName = headers['content-disposition'].split('filename=')[1];
            const filename = rawFileName.match(/"([^"]+)"/)[1];

            downloadCSVFile(text, filename);
        } catch(error) {
            if (error.status === 401) {
                logout();
            }
            onError && onError(error);
        }
    };

    return (
        <Button
            variant={"contained"}
            startIcon={<DownloadCsvIcon/>}
            onClick={handleClick}
            className={className}
        >
            {title || "Download"}
        </Button>
    );
};

export default DownloadCSVButton;