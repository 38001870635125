import React from 'react';
import { Grid } from '@mui/material';
import TestimonialItem from './TestimonialItem';
import './testimonials.css'


const TestimonialItemList = () => {
    return (<Grid
        container
        spacing={2}
        className="ss-customer-partner-testimonials-container"
        direction='column'
        justifyContent='space-evenly'
    >
        <div className='flex_box'>
            <TestimonialItem
                content="SecureSpace is an easy to use one stop shop platform we use for all our nationwide parking needs. The partnership with SecureSpace has been extremely valuable to us and managing our large fleet of semi-trailers. We love it!"
                authorName="Joe Servais, Fleet Manager"
                companyName="Victory Equipment & Services"
                bookings={20}
                imageSrc="../app-images/testimonialCompanyLogos/victory_V.png"
            />
            <TestimonialItem
                content="As a trucking operator, SecurSpace's web based tools have allowed us to scale up storage for our short and medium-term needs. As a yard operator, SecurSpace provides great flexibility in choices for the properties we operate in, nationwide. The partnership has been beneficial on multiple fronts throughout our multi year relationship."
                authorName="Steve Jackson"
                companyName="Cargomatic"
                bookings={20}
                imageSrc="../app-images/testimonialCompanyLogos/cargomatic.png"
            />
            <TestimonialItem
                content="We have been working with SecurSpace since their inception. Their website is intuitive and easy to use. Our team enjoys working with their customer service team because they are very responsive and professional. Whether we are looking to lease some yard space or in need of some yard space, we look to SecurSpace to service our needs."
                authorName="Robert Loya"
                companyName="TGS"
                bookings={20}
                imageSrc="../app-images/testimonialCompanyLogos/TSG.png"
            />
            <TestimonialItem
                content="SecurSpace website is easy to navigate and it has simple steps to book or secure a space before sending the driver to drop off a unit. Payment is straight forward."
                authorName={null}
                companyName="Kojus Trans Logistics"
                bookings={10}
                imageSrc=""
            />
            <TestimonialItem
                content="SecūrSpace helped out our company so promptly yesterday. Highly skilled- we love that!"
                authorName="Buyer"
                companyName="PJ Trans, Inc"
                bookings={10}
                imageSrc=""
            />
            <TestimonialItem
                content="We like using SecūrSpace because of how easy it is to book space."
                authorName="Eduardo"
                companyName="Unified Global Logistics"
                bookings={10}
                imageSrc=""
            />
        </div>
    </Grid>);
};

export default TestimonialItemList;