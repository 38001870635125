import React, { useState, useEffect, useContext } from 'react';
import { Grid, Typography, Snackbar, Container } from '@mui/material'
import { Link } from 'react-router-dom';
import { AppContext } from "../context/app-context";

import SearchRedirectPopularLocationListingItem from './SearchRedirectPopularLocationListingItem';

import '../css/components/popularLocationListings.css';
import { getCitiesByRank } from './location/requests/location-requests';
import { getErrorMessageForNonStandardAndStandardResponse } from '../util/NetworkErrorUtil';

const DEFAULT_NUM_CITIES = 12;
const DEFAULT_ERROR_MESSAGE = "Failed to fetch popular locations";

const PopularLocationListing = (props) => {
    const [cityLimit, setCityLimit] = useState(DEFAULT_NUM_CITIES);
    const [cities, setCities] = useState([]);
    const [errorMessage, setErrorMessage] = useState('');
    const [showErrorMessage, setShowErrorMessage] = useState(false);

    const { user, loadingUserDetails } = useContext(AppContext);

    useEffect(() => {
        if (props.numCities) setCityLimit(props.numCities);
    }, [props.numCities]);

    useEffect(() => {
        getCitiesByRank(0, cityLimit, "rank", "DESC").then((response) => {
            if (response.body) {
                setCities(response.body.content);
            } else {
                setCities([]);
            }
        }).catch((error) => {
            setShowErrorMessage(true);
            setErrorMessage(`${DEFAULT_ERROR_MESSAGE}: ${getErrorMessageForNonStandardAndStandardResponse(error)}`);
        });
    }, [cityLimit]);

    return (
        <Container className='ss-popular-location-list' maxWidth="lg">
            <Grid container className="popular-location-listing-container-grid">
                <Grid item container justifyContent="space-between" alignItems="flex-end">
                    <Grid item>
                        <Typography variant="h4" className="header1" color="textPrimary">
                            Popular Locations
                        </Typography>
                    </Grid>
                    {
                        (!user || loadingUserDetails) && <Grid item>
                            <Typography variant="subtitle1" className="header2" color="textPrimary">
                                Want to List Your Location Here? <Link to={{ pathname: "/partner-signup" }} className="sign-up-link">Click here</Link> to sign up
                            </Typography>
                        </Grid>
                    }
                </Grid>
                <Grid className="location-panel-container" item container>
                    {
                        cities.map((cityObj) => {
                            return (
                                <SearchRedirectPopularLocationListingItem cityObj={cityObj} key={cityObj.id} />
                            );
                        })
                    }
                </Grid>
            </Grid>
            {
                showErrorMessage && <Snackbar
                    open={showErrorMessage}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                    severity="warning"
                    message={errorMessage}
                    autoHideDuration={6000}
                    onClose={() => setShowErrorMessage(false)}
                />
            }
        </Container>
    );
};

export default PopularLocationListing;
