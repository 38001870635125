import React, {useEffect, useState} from "react";
import { BookingCard } from "@securspace/securspace-ui-kit";
import {BookingStatus, BookingStatusesEligibleForPaymentMethodUpdate} from '../constants/securspace-constants';
import { makeStyles } from "@mui/styles";
import { startDateLessThan24Hours } from "../../util/BookingUtil.js";

const CANCELLED = 'CANCELLED';

const useStyles = makeStyles(() => ({
  mb:{
    marginBottom: '1rem'
  }
}));

const BookingCardItem = ({booking, headerCells, onEnd, onUpdate, onShowPaymentModal, onShowCancelModal}) => {
    const [headCells, setHeadCells] = useState(headerCells);

    const {id, status, bookingSubscriptionStatus, frequency, brokered} = booking;

    const showEndBookingStatuses = [BookingStatus.APPROVED, BookingStatus.PAYMENT_DECLINED, BookingStatus.PAYMENT_DECLINED_ADJUSTED, BookingStatus.PROCESSING_ACH_PAYMENT]

    const classes = useStyles();

    const RATE = 'rate';

    const RECURRING = "RECURRING";

    const oneTimeBookingStartDateMoreThan24Hrs = frequency === RECURRING ? true : !startDateLessThan24Hours(booking) ? true : false;

    const buttons = [];

    if (bookingSubscriptionStatus !== CANCELLED && showEndBookingStatuses.includes(status) && oneTimeBookingStartDateMoreThan24Hrs) {
      buttons.push(
        {
          label: frequency === RECURRING ? "End Booking" : "Cancel Booking",
          onClick: onEnd,
          variant: 'text',
          styles: {
            color: (theme) => theme.palette.error.main,
          }
        }
      );
    }

    if (BookingStatusesEligibleForPaymentMethodUpdate.includes(status)) {
      buttons.push(
        {
          label: 'Update Payment Method',
          onClick: onUpdate,
          variant: 'outlined'
        }
      );
    }
    if (status === BookingStatus.INCOMPLETE) {
      buttons.push(
          {
              label: 'Complete Booking',
              onClick: onShowPaymentModal,
              variant: 'outlined'
          }
      );
  }
  if (status === BookingStatus.PENDING) {
      buttons.push(
          {
              label: 'Cancel Booking Request',
              onClick: onShowCancelModal,
              variant: 'text',
              styles: {
                  color: (theme) => theme.palette.error.main,
              }
          },
      );
  }

    useEffect(() => {
      if (brokered) {
        let newCells = headerCells.filter(cell => cell.id !== RATE)
        setHeadCells(newCells);
      } else {
        setHeadCells(headerCells)
      }
    }, [headerCells]);

    return (
      <BookingCard
        headCells={headCells}
        rootStyle={classes.mb}
        key={id}
        data={booking}
        buttons={buttons}
      />
    );
};

export default BookingCardItem;
