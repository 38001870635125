import React, { useContext, useState, useEffect } from 'react';
import { AppContext } from "../context/app-context";
import NavMenu from "../views/nav-menu/NavMenu";
import { Link } from "react-router-dom";
import { useNavigate, useLocation } from 'react-router-dom';
import MobileMenu from './MobileMenu';
import { AccountType, UserType } from '../components/constants/securspace-constants';
import { useFlags } from "launchdarkly-react-client-sdk";

const BaseNav = ({ pendingApprovalCount, pendingInventoryCount, leftNavOptions, rightNavOptions, fontColorOverride, logoUrl }) => {
    const context = useContext(AppContext)
    const [isSticky, setIsSticky] = useState(false);
    const { buyerSpaceUsageRedesign, supplierSpaceUsageRedesign } = useFlags();

    const { clearUser, user } = context
    const [showMenu, setShowMenu] = useState(false);
    const [expanded, setExpanded] = useState("");
    const history = useNavigate();
    const location = useLocation();

    function closeNavMenu() {
        setShowMenu(false)
    }

    function handleMenu(menuItem) {
        closeNavMenu();
        history(`/${menuItem.path}`)
        if (menuItem.label === 'How It Works') {
            const element = document.getElementById('how-it-works');
            element && element.scrollIntoView({ behavior: 'smooth' })
        }
    }

    function handleClick(name) {
        if (name === expanded) {
            return setExpanded("")
        }
        setExpanded(name);
    }


    const handleScroll = () => {
        if (window.scrollY > 30) {
            setIsSticky(true);
        } else {
            setIsSticky(false);
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);


    return (
        <>
            <div id="appNav" className={`user-based-nav-container ${isSticky ? 'sticky' : ''} ${location.pathname === '/about' && user?.userType === UserType.ADMIN && user?.type !== AccountType.ADMIN  && !isSticky && `ss-margin-top`} ${!fontColorOverride ? `ss-other-page-nav` : `ss-nav-menu`} ${location.pathname === '/' && user?.userType === UserType.ADMIN && user?.type !== AccountType.ADMIN && `ss-margin-none`}`}>
                <Link id="navLogoContainer" to="/">
                    <img id="navLogoTrans"
                        src={isSticky ? `/app-images/logo/secure-space_white-blue_WTG.svg` : logoUrl}
                        alt="SecūrSpace" />
                </Link>
                <div id="navMenuContainerRight">
                    <div id={`${location.pathname === '/' ? `ss-light_menu_for_landing_page` : `toggleMenuIcon`}`} onClick={() => setShowMenu(true)} />
                    <NavMenu
                        navMenu={rightNavOptions}
                        handleLogout={clearUser}
                        showMenu={showMenu}
                        closeNavMenu={() => setShowMenu(false)}
                        pendingApprovalCount={pendingApprovalCount}
                        pendingInventoryCount={pendingInventoryCount}
                        overrideFontColor={fontColorOverride}
                        isSticky={isSticky}
                        buyerSpaceUsageRedesign={user?.type === AccountType.BUYER ? buyerSpaceUsageRedesign : supplierSpaceUsageRedesign}
                    />
                </div>
            </div>
            <MobileMenu
                closeNavMenu={closeNavMenu}
                handleClick={handleClick}
                handleMenu={handleMenu}
                leftNavOptions={leftNavOptions}
                rightNavOptions={rightNavOptions}
                showMenu={showMenu}
                expanded={expanded}
                buyerSpaceUsageRedesign={user?.type === AccountType.BUYER ? buyerSpaceUsageRedesign : supplierSpaceUsageRedesign}
                pendingApprovalCount={pendingApprovalCount}
                pendingInventoryCount={pendingInventoryCount}
            />
        </>
    )
}

export default BaseNav;
