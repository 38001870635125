import React, {useState, useEffect, useCallback} from "react";
import { Dialog, DialogActions, DialogTitle, DialogContent, Button, Grid, Typography, FormControl, RadioGroup, FormLabel, FormControlLabel, Radio } from "@mui/material";
import { withSnackbar } from "../../hocs/withSnackbar";
import { requestPaymentMethods} from "../../paymentMethods/request/payment-method-requests";
import { BankAccountStatus, AuthorityType } from "../../constants/securspace-constants";
import Busy from "../../Busy";
import { PaymentType } from "../../constants/securspace-constants";
import { calculateBookingChargeAmount } from "../../../views/requests/booking-requests";
import moment from "moment";
import { DateFormats } from "../../constants/securspace-constants";
import {Link} from "react-router-dom";
import {Theme} from "@mui/material";
import {makeStyles} from "@mui/styles";
import RentalAgreementCheckbox from "../../RentalAgreementCheckbox";

const useStyles: (theme: Theme) => {link: CSSStyleSheet} = makeStyles((theme) => ({
  link: {
    fontSize: "inherit",
    color: theme.palette.secondary.main,
    textDecoration: 'none',
  }
}));

const PaymentMethodModal = ({open, account, booking, snackbarShowMessage, handleNext, setCalculateCostObject, selectedPaymentMethod, setSelectedPaymentMethod, onClose, classNames}) => {

  const [paymentMethods, setPaymentMethods] = useState([]);
  const [agreementAccepted, setAgreementAccepted] = useState(false);
  const [error, setError] = useState("");
  const classes = useStyles();

  const loadPaymentMethods = useCallback(() => {
    Busy.set(true);
    requestPaymentMethods(
      account.authorityId || account.id,
      account.authorityType ? account.authorityType : AuthorityType.ACCOUNT,
      (response) => {
        setPaymentMethods(response);
        Busy.set(false);
      },
      (error) => {
        Busy.set(false);
        snackbarShowMessage(error, 'error', 15000);
      }
    );
  }, [account, snackbarShowMessage]);

    useEffect(() => {
        loadPaymentMethods();
    }, [loadPaymentMethods]);

    const showPaymentReview = (e) => {
        Busy.set(true);
        e.preventDefault();
        if (!agreementAccepted) {
            setError( "Please read and accept the agreement to book space.")
        } else {
            setError("");
            handleNext();
        }
        Busy.set(false);
    };

    const handleToggleAgreement = () => {
        setAgreementAccepted(prev => !prev)
    }

    const calculateCreditCardFees = () => {
            const data = {
                id: booking.id,
                supplierAccountId: booking.supplierAccountId,
                buyerAccountId: booking.buyerAccountId,
                locationId: booking.locationId,
                numberOfSpaces: booking.numberOfSpaces,
                startDate: moment(booking.startDate).format(DateFormats.DAY),
                endDate: moment(booking.endDate).format(DateFormats.DAY),
                frequency: booking.frequency,
                recurrences: booking.recurrences,
                durationType: booking.durationType,
                rate: booking.rate,
                assetType: booking.assetType,
                brokeredBooking: booking.brokered,
                brokeredInitialChargeAlreadyPaid: booking.brokeredInitialChargeAlreadyPaid,
                brokeredRecurringChargeAlreadyPaid: booking.brokeredRecurringChargeAlreadyPaid,
                brokeredSupplierPaidPerOccurrence: booking.brokeredSupplierPaidPerOccurrence,
                brokeredBuyerChargedPerOccurrence: booking.brokeredBuyerChargedPerOccurrence,
                brokeredSupplierOverageRatePaid: booking.brokeredSupplierOverageRatePaid,
                brokeredBuyerOverageRateCharged: booking.brokeredBuyerOverageRateCharged,
                paymentMethodId: selectedPaymentMethod?.id
            }
        calculateBookingChargeAmount(data).then(res => setCalculateCostObject(res.body)).catch(err => snackbarShowMessage(err, 'error', 15000));
    };

    const handlePaymentMethodChange = event => {
        let value = event.target.checked;
        let paymentMethodId = event.target.value;

        if (value) {
            let newSelection = paymentMethods.find(paymentMethod => {
                return paymentMethod.id === paymentMethodId;
            });
            setSelectedPaymentMethod(newSelection)
        }
    };

    useEffect(() => {
        if (selectedPaymentMethod) {
            calculateCreditCardFees()
        }
    }, [selectedPaymentMethod])

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>Complete Booking</DialogTitle>
            <DialogContent>
                <FormControl className="ss-book-space-form ss-form ss-block no-padding">

                    {
                        <Grid container className="modal-body">
                            <Grid container id="selectPaymentMethod" flexDirection="column">
                                <FormLabel className="ss-summary" id="selectPaymentMethodLabel">
                                    1. Select Payment Method
                                </FormLabel>

                                <RadioGroup id="paymentMethodOptions" aria-labelledby="selectPaymentMethodLabel" value={selectedPaymentMethod?.id || ''}>
                                    {
                                        paymentMethods.length ?
                                        paymentMethods.filter((paymentMethod) => paymentMethod.type === PaymentType.ACH ? paymentMethod.dwollaStatus === BankAccountStatus.VERIFIED : true)
                                            .map((paymentMethod) => (
                                                <FormControlLabel 
                                                    key={paymentMethod.id}
                                                    control={<Radio className={classNames.radio}/>}
                                                    id={paymentMethod.id} 
                                                    name="paymentMethodOptions"
                                                    onChange={handlePaymentMethodChange}
                                                    value={paymentMethod.id}
                                                    label={`${paymentMethod.type === 'CARD' ? paymentMethod.cardBrand : paymentMethod.bankName} ending in ${paymentMethod.lastFour}`}
                                                />
                                            )
                                        )
                                        : "Please add a payment method"
                                    }
                                    {
                                        selectedPaymentMethod && selectedPaymentMethod.type === 'CARD' ?
                                            <p className="help-block">
                                            <span
                                                className="glyphicon glyphicon-info-sign"
                                                aria-hidden="true"/>
                                                Additional credit card
                                                processing fee
                                                applies
                                            </p>
                                            :
                                            ''
                                    }
                                </RadioGroup>

                                <FormLabel className="ss-summary" id="tosAgreementLabel">
                                  2. Accept <Link to={'/terms-of-use'} target={"_blank"} className={classes.link}>Terms of Service</Link> Agreement:
                                </FormLabel>
                                <RentalAgreementCheckbox checked={agreementAccepted} onChange={handleToggleAgreement} required/>
                                {
                                    error ?
                                        <Grid item>
                                            <Typography variant="subtitle1" color="tomato">{error}</Typography> 
                                        </Grid>
                                    : ''
                                }

                            </Grid>
                        </Grid>

                    }

                </FormControl>
            </DialogContent>
            <DialogActions>
                <Grid container justifyContent={"center"}>
                    <Button variant="text" onClick={onClose} >
                        Cancel
                    </Button>
                    <Button variant="contained" onClick={showPaymentReview}>
                        Next
                    </Button>
                </Grid>
            </DialogActions>
        </Dialog>

    )
}

export default withSnackbar(PaymentMethodModal);
