import React from 'react';
import {Grid} from "@mui/material";
import {SortFilter} from "@securspace/securspace-ui-kit";
import { requestLocations } from '../../location/requests/location-requests'
import useGetRequestBody from "../../../hooks/useGetRequestBody";
import {withSnackbar} from "../../hocs/withSnackbar";

const SpaceUsageFilterForm = (props: {
  supplierAccountId: string,
  filterByOptions?: {name: string, label: string, value: string}[],
  onChange: (event) => void,
  defaultStartDate: string,
  defaultEndDate: string,
  snackbarShowMessage: () => void
}) => {
  const {supplierAccountId = '', filterByOptions = [], onChange = () => {}, defaultStartDate, defaultEndDate, snackbarShowMessage = () => {}} = props;

  const locations = useGetRequestBody(requestLocations, [supplierAccountId], snackbarShowMessage);

  const sortByOptions = [
    {value: 'calendarDate', label: 'Date'},
    {value: 'totalSpacesBooked', label: 'Booked Spaces'},
    {value: 'maxInventoryCount', label: 'Max Inventory'},
    {value: 'overageSpacesChargedCount', label: 'Charged Overages'},
    {value: 'buyerCompanyName', label: 'Customer'},
    {value: 'locationName', label: 'Location'},
    {value: 'supplierCompanyName', label: 'Partner'},
    {value: 'bookingNumbers', label: 'Bookings'},
    {value: 'overageInvoiceNumbers', label: 'Overage Invoices'},
  ];

  return (
    <Grid mb={2} item container direction={'column'} component={'section'}>
    <SortFilter
      filterCallback={onChange}
      sortByOption={sortByOptions}
      locations={locations}
      filterByOption={filterByOptions}
      defaultStartDate={defaultStartDate}
      defaultEndDate={defaultEndDate}
      defaultSortBy={'calendarDate'}
      sortDir={'asc'}
    />
    </Grid>
  );
};

export default withSnackbar(SpaceUsageFilterForm);
