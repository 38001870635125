import React from 'react'

const internalContext = React.createContext({
    showLoginModal: () : void => {},
    closeLoginModal: () : void => {},
    showSignUpModal: () : void => {},
    closeSignUpModal: () : void => {},
    showForgotPasswordModal: () : void => {},
    closeForgotPasswordModal: () : void => {},
    showTermsModal: () : void => {},
    closeTermsModal: () : void => {},
    showPrivacyModal: () : void => {},
    closePrivacyModal: () : void => {},
    showVersionedTermsModal: () => {},
    closeVersionedTermsModal: () => {},
    showVersionedPrivacyModal: () => {},
    closeVersionedPrivacyModal: () => {}
})

internalContext.displayName = "SecurSpaceGlobalModalContext"

export const GlobalModalContext = internalContext
