import React, { useContext } from 'react'
import { useNavigate } from "react-router";
import { AppContext } from "../../../context/app-context";
import { SocialLoginContext } from "../../../context/social-login-context";
import { Navigate } from "react-router";
import { getLandingRedirectPathForUser } from "../../../routing/route-utils";
import SessionPoller from "../../../services/session/SessionPoller";
import Login from "../../../views/Login";
import PropTypes from 'prop-types'
import {LOGIN_PATH} from '../../constants/securspace-constants';


const ContextualLogin = (props) => {
    const appContext = useContext(AppContext)
    const { user, loadingUserDetails, updateUser } = appContext

    const socialLoginContext = useContext(SocialLoginContext)
    const { setSocialLoginUser, clearSocialLoginUser } = socialLoginContext

    const history = useNavigate()

    const redirectPath = getLandingRedirectPathForUser(user)

    const updateUserAndRedirect = (user: Object, redirectPath: String) => {
        updateUser(user, history, redirectPath)
        SessionPoller.beginPolling(() => {
            updateUser(undefined)
            history(`${LOGIN_PATH}?timeout=true`)
        })
    }

    let content

    if(loadingUserDetails) {
        content = <div /> // todo za -- loading component
    } else if(user && user.id) {
        content = <Navigate to={redirectPath} />
    } else {
        content = <Login
            { ...props }
            account={user}
            handleAccountChange={updateUserAndRedirect}
            setSocialLoginUser={setSocialLoginUser}
            clearSocialLoginUser={clearSocialLoginUser}
        />
    }

    return content
}

ContextualLogin.propTypes = {
    cancel: PropTypes.func.isRequired,
    handleForgotPasswordNavigation: PropTypes.func.isRequired,
    handleSignUpNavigation: PropTypes.func.isRequired,
}

export default ContextualLogin
