import React, { useRef, useCallback } from "react";
import { Grid } from '@mui/material'
import { SortFilter } from '@securspace/securspace-ui-kit';
import { filterByOptions, filterBySelectOption, sortByFields } from './data';
import { requestLocations } from '../../components/location/requests/location-requests'
import useGetRequestBody from "../../hooks/useGetRequestBody";
import {withSnackbar} from "../hocs/withSnackbar";

const SupplierBookingFilter = ({
  supplierAccountId,
  setSelectedLocation,
  setStartDate,
  setEndDate,
  setSortBy,
  setSortDir,
  setFilter,
  setEquipmentType,
  setStatus,
  setActive,
  setBrokered,
  setFrequency,
  snackbarShowMessage
}) => {

  const locations = useGetRequestBody(requestLocations, [supplierAccountId], snackbarShowMessage);
  const initialRender = useRef(true);

  const handleCallback = useCallback((e) =>{
    if (!initialRender.current) {
      setSortBy(e.sortBy);
      setSelectedLocation(e.location);
      setStartDate(e.startDate);
      setFilter(e.filters?.find(item => item.name === "buyerName")?.value);
      setEquipmentType(e.selectFilters?.find(item => item.name === "equipmentType")?.value);
      setStatus(e.selectFilters?.find(item => item.name === "status")?.value);
      setActive(e.selectFilters?.find(item => item.name === "active")?.value);
      setBrokered(e.selectFilters?.find(item => item.name === "brokered")?.value);
      setFrequency(e.selectFilters?.find(item => item.name === "frequency")?.value);
      setEndDate(e.endDate);
      setSortDir(e.sortDir);
    } else {
      initialRender.current = false;
    }
  },[setSortBy, setSelectedLocation, setStartDate, setEndDate, setFilter])

  return (
    <Grid item mb={4} sx={{
      '& .MuiInputLabel-root.Mui-error':{
        color:'rgba(0, 0, 0, 0.6) !important'
      },
      '& .MuiInput-root.Mui-error:before, .MuiInput-root.Mui-error:after':{
        borderBottom: '1px solid rgba(0, 0, 0, 0.42) !important'
      }
    }}>
      <SortFilter
        filterCallback={handleCallback}
        sortByOption={sortByFields}
        locations={locations}
        filterByOption={filterByOptions}
        filterBySelectOption={filterBySelectOption}
        firstDatePickerLabel="From Date"
        secondDatePickerLabel="To Date"
        defaultSortBy={'startDate'}
        sortDir={'desc'}
      />
    </Grid>
  )
}

export default withSnackbar(SupplierBookingFilter);
