import React from 'react';
import {Checkbox, FormControlLabel, Grid, Typography} from "@mui/material";
import { makeStyles } from '@mui/styles';
import {Theme} from "@mui/material";
import PropTypes from "prop-types";
import {isZeroPartnerPayout, isZeroSecurSpacePayout} from "../../../util/BookingUtil";

const useStyles: (theme: Theme) => {
  adminConfirm: CSSStyleSheet,
  fontWhite: CSSStyleSheet,
  payoutConfirmedCheckbox: CSSStyleSheet
} = makeStyles(theme => ({
  adminConfirm: {
    backgroundColor: theme.palette.primary.main,
      padding: '1rem',
      borderRadius: '0.57rem',
  },
  fontWhite: {
    color: theme.palette.primary.contrastText,
  },
  payoutConfirmedCheckbox: {
    '&.Mui-checked, &.MuiCheckbox-root': {
      color: theme.palette.primary.contrastText,
    }
  },
}));

const AdminBrokeredBookingConfirmation = ({checked = false, onChange, customerChargedAmountPerPeriod, partnerPayoutAmountPerPeriod}) => {
  const classes = useStyles();

  let confirmationMessage = '';

  if (isZeroSecurSpacePayout(customerChargedAmountPerPeriod, partnerPayoutAmountPerPeriod)) {
    confirmationMessage = "SecūrSpace payout will be 0";
  } else if (isZeroPartnerPayout(customerChargedAmountPerPeriod, partnerPayoutAmountPerPeriod)) {
    confirmationMessage = "SecūrSpace fees are 100%";
  }

  return (
   <Grid item className={classes.adminConfirm}>
      <Typography variant='h5' align='center' component='h6' className={classes.fontWhite}>Please Confirm</Typography>
      <FormControlLabel
        label={<Typography variant='body1' component={'strong'} className={classes.fontWhite}>{confirmationMessage}</Typography>}
        control={<Checkbox name='ssPayoutConfirmed' checked={checked} onChange={onChange} className={classes.payoutConfirmedCheckbox} />}
      />
   </Grid>
  );
};

AdminBrokeredBookingConfirmation.propTypes = {
  checked: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  customerChargedAmountPerPeriod: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  partnerPayoutAmountPerPeriod: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};

export default AdminBrokeredBookingConfirmation;
