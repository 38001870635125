import React, {Component} from 'react';
import {formatCurrencyValue} from "../util/PaymentUtils";
import AccountReport from "./AccountReport";
import getDisplayValue from "../components/BuyerTransactionStatus";
import URLUtils from "../util/URLUtils";
import Busy from "../components/Busy";
import {toast} from "react-toastify";
import {createLogoutOnFailureHandler} from "../util/LogoutUtil";
import {
  BookingTransactionStatusType,
  BookingTransactionType,
  BookingTransactionTypeLabel
} from "../components/constants/securspace-constants";
import { withRouter } from '../hooks/withRouter';
import {loadBuyerTransactions} from "../requests/transaction-requests";
import {getErrorMessageForNonStandardAndStandardResponse} from "../util/NetworkErrorUtil";

const $ = window.$;

class BuyerInvoicesReport extends Component {
    constructor(props) {
        super(props);

        let initialSearchText = URLUtils.getQueryVariable('invoiceNumber');
        if (!initialSearchText) {
            initialSearchText = '';
        }

        this.state = {
            account: this.props.account,
            initialSearchText: initialSearchText,
            bookingTransactions: [],
        };
    }

    componentDidMount() {
      this.loadTransactions();
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.account !== this.state.account) {
            this.setState({account: nextProps.account});
            this.loadTransactions();
        }
    }

    loadTransactions() {
      loadBuyerTransactions(this.props.account.id)
        .then(response => {
          const formattedTransactions = [];
          response.body.forEach(transaction => {
            if (transaction.firstIntervalTransactionEndDate && transaction.firstIntervalTransactionStartDate) {
              transaction.endDate = transaction.firstIntervalTransactionEndDate;
              transaction.startDate = transaction.firstIntervalTransactionStartDate;
            }
            formattedTransactions.push(transaction);
          });
          this.setState({bookingTransactions: formattedTransactions});
        })
        .catch(error => {
          toast.error(getErrorMessageForNonStandardAndStandardResponse(error));
        });
    }

    viewInvoice = item => {
        if (item) {
            window.open("/buyer-invoice/" + item?.transactionNumber);
        }

    };

    viewInventoryLog = item => {
        if (item) {
            window.open('/api/overage-daily-report/' + item?.transactionId);
        }
    };

    refreshPaymentStatus = (item) => {
        Busy.set(true);
        $.ajax({
            url: '/api/booking-transaction/' + item.transactionId + '/refresh-payment-status',
            data: JSON.stringify({}),
            type: 'POST',
            contentType: 'application/json; charset=UTF-8',
            success: () => {
                Busy.set(false);
                toast.success("Successfully refreshed payment status!");
            },
            statusCode: {
                401: createLogoutOnFailureHandler(this.props.handleLogout)
            },
            error: () => {
                Busy.set(false);
                toast.error("Failed to refresh payment status");
            }
        });
    };

    approveDisputeInvoice = (item) => {
      this.props.history(`/buyer/service-fee/${item.transactionId}`);
    }

    render() {
        return (
            <div className="flex h-100">
            <AccountReport title="Invoices"
                           data={this.state.bookingTransactions}
                           defaultGroupBy="supplierCompanyName"
                           defaultSortBy="serviceStartDate"
                           defaultDaysInDateRange={30}
                           visibleRecordBatchSize={20}
                           criteriaField="locationName"
                           dateField="createdOn"
                           initialSearchText={this.state.initialSearchText}
                           reportFields={[
                               {
                                   label: "INVOICE NUMBER",
                                   name: "transactionNumber",
                                   groupable: false
                               },
                               {
                                   label: "AMOUNT",
                                   name:  "buyerAmount",
                                   formatter:  (value) => {
                                       return formatCurrencyValue(value);
                                   },
                                   groupable: false
                               },
                               {
                                   label: "PAYMENT TYPE",
                                   name:  "paymentType",
                                   groupable: false,
                                   searchable: false
                               },
                               {
                                   label: "PAYMENT METHOD",
                                   name:  "paymentMethodDescription"
                               },
                               {
                                   label: "INVOICE TYPE",
                                   name:  "transactionType",
                                   formatter: (type) => {
                                       return BookingTransactionTypeLabel[type] || type;
                                   }
                               },
                               {
                                   label: "SPACES",
                                   name:  "numberOfSpaces",
                                   groupable: false
                               },
                               {
                                   label: "Start Date",
                                   name: "startDate",
                                   shouldShowField: (transaction) => {
                                    return transaction.transactionType !== BookingTransactionType.SERVICE_CHARGE;
                                   }
                               },
                               {
                                   label: "End Date",
                                   name: "endDate",
                                   shouldShowField: (transaction) => {
                                    return transaction.transactionType !== BookingTransactionType.SERVICE_CHARGE;
                                   }
                               },
                               {
                                   label: "INVOICE CREATED",
                                   name: "createdOn"
                               },
                               {
                                   label: "PAYMENT INITIATED",
                                   name: "paymentCreatedOn"
                               },
                               {
                                   label: "PAYMENT COMPLETED",
                                   name: "paymentCompletedOn"
                               },
                               {
                                   label: "STATUS",
                                   name:  "status",
                                   formatter: (status, invoice) => {
                                     if (invoice.transactionType === BookingTransactionType.SERVICE_CHARGE) {
                                       return 'Awaiting Customer Approval'
                                     } else {
                                       return getDisplayValue(status)
                                     }
                                   }
                               },
                               {
                                   label: "BOOKING",
                                   name:  "bookingNumber"
                               },
                               {
                                   label: "LOCATION",
                                   name:  "locationName"
                               },
                               {
                                   label: "PARTNER",
                                   name:  "supplierCompanyName"
                               },
                               {
                                 label: "Equipment Type",
                                 name: "assetType"
                               },
                               {
                                 label: "Notes",
                                 name: "statusNotes",
                                 shouldShowField: (transaction) => {
                                   return transaction.transactionType === BookingTransactionType.SERVICE_CHARGE;
                                 }
                               }
                           ]}
                           account={this.state.account}
                           actionList={
                               [
                                   {
                                       displayValue: 'View Invoice',
                                       action: this.viewInvoice
                                   },
                                   {
                                       displayValue: 'View History',
                                       action: this.viewInventoryLog,
                                   },
                                   {
                                       displayValue: 'Refresh Payment Status',
                                       action: this.refreshPaymentStatus,
                                       shouldShowAction: (transaction) => {
                                           return transaction.status === BookingTransactionStatusType.PAYMENT_PENDING;
                                       }
                                   },
                                   {
                                       displayValue: 'Approve/Dispute Invoice',
                                       action: this.approveDisputeInvoice,
                                       shouldShowAction: (transaction) => {
                                         return transaction.transactionType === BookingTransactionType.SERVICE_CHARGE &&
                                           transaction.status === BookingTransactionStatusType.CHARGE_ON_HOLD;
                                       }
                                   },
                               ]
                           }
            />

            </div>
                )
    }
}

export default withRouter(BuyerInvoicesReport);
