import React, { useRef, useCallback } from "react";
import { Grid } from "@mui/material";
import { SortFilter } from '@securspace/securspace-ui-kit';
import moment from "moment";
import { DateFormats } from "../constants/securspace-constants";
import { filterByOptions, filterBySelectOption, groupOption } from "./data";
import { getAllLocationsByBuyer } from "../../components/location/requests/location-requests";
import useGetRequestBody from "../../hooks/useGetRequestBody";
import {withSnackbar} from "../hocs/withSnackbar";

const BuyerInterChangesFilters = React.memo(({
  buyerAccountId,
  setStartDate,
  setEndDate,
  setSortBy,
  setSelectedLocation,
  setEquipmentNumber,
  setEquipmentType,
  setSupplierName,
  snackbarShowMessage,
  setSortDir
}) => {

  const locations = useGetRequestBody(getAllLocationsByBuyer, [buyerAccountId], snackbarShowMessage);
  const initialRender = useRef(true);

  const parseFilters = (filterArray) => {
    const supplierName = filterArray.find(item => item.name === "supplierName")?.value
    const equipmentNumber = filterArray.find(item => item.name === "equipmentNumber")?.value

    return {
      supplierName,
      equipmentNumber,
    }
  }

  const handleCallback = useCallback((e) => {
    if (!initialRender.current) {
      setStartDate(e.startDate);
      setEndDate(e.endDate);
      setSortBy(e.sortBy);
      const filters = parseFilters(e.filters);
      setEquipmentNumber(filters.equipmentNumber);
      setEquipmentType(e.selectFilters?.find(item => item.name === "equipmentType")?.value);
      setSupplierName(filters.supplierName);
      setSelectedLocation(e.location);
      setSortDir(e.sortDir)
    } else {
      initialRender.current = false;
    }
  }, [setStartDate, setEndDate, setSortBy, setSelectedLocation, setEquipmentNumber, setEquipmentType, setSupplierName, parseFilters]);

  const lastMonthDate = moment().add(-1, 'months').format(DateFormats.DAY).toString();
  const todayDate = moment().format(DateFormats.DAY).toString();

  return (
    <Grid item mb={4}>
      <SortFilter
        filterCallback={handleCallback}
        locations={locations}
        filterByOption={filterByOptions}
        defaultStartDate={lastMonthDate}
        defaultEndDate={todayDate}
        sortByOption={groupOption}
        filterBySelectOption={filterBySelectOption}
        defaultSortBy={"activityDate"}
        sortDir={"desc"}
      />
    </Grid>
  )
});

export default withSnackbar(BuyerInterChangesFilters);
