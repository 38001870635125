import React, { useRef, useCallback } from "react";
import { Grid } from '@mui/material'
import { SortFilter } from '@securspace/securspace-ui-kit'
import moment from 'moment'
import { filterByOptions, sortByFields, filterBySelectOption } from './data'
import { DateFormats } from '../../constants/securspace-constants';
import { requestLocations } from '../../../components/location/requests/location-requests'
import useGetRequestBody from "../../../hooks/useGetRequestBody";
import {withSnackbar} from "../../hocs/withSnackbar";

const SupplierInvoicesFilter = React.memo(({
  supplierAccountId,
  setSelectedLocation,
  setStartDate,
  setEndDate,
  setBookingNumber,
  setInvoiceNumber,
  setInvoiceType,
  setStatus,
  setSortBy,
  setSortDir,
  snackbarShowMessage
}) => {

  const locations = useGetRequestBody(requestLocations, [supplierAccountId], snackbarShowMessage);
  const initialRender = useRef(true);

  const handleCallback = useCallback((e) => {
    if (!initialRender.current) {
      setSelectedLocation(e.location);
      setStartDate(e.startDate)
      setEndDate(e.endDate)
      setBookingNumber(e.filters?.find(item => item.name === "bookingNumber")?.value);
      setInvoiceNumber(e.filters?.find(item => item.name === "transactionNumber")?.value);
      setInvoiceType(e.selectFilters?.find(item => item.name === "transactionType")?.value);
      setStatus(e.selectFilters?.find(item => item.name === "status")?.value);
      setSortBy(e.sortBy)
      setSortDir(e.sortDir)
    }
    else {
      initialRender.current = false;
    }
  }, [])

  const lastMonthDate = moment().add(-1, 'months').format(DateFormats.DAY).toString();
  const todayDate = moment().format(DateFormats.DAY).toString();

  return (
    <Grid container mb={4} flexDirection={'column'}>
      <SortFilter
        filterCallback={handleCallback}
        sortByOption={sortByFields}
        defaultEndDate={todayDate}
        defaultStartDate={lastMonthDate}
        locations={locations}
        filterByOption={filterByOptions}
        filterBySelectOption={filterBySelectOption}
        defaultSortBy={'transactionNumber'}
        sortDir={'desc'}
      />
    </Grid>
  )
})

export default withSnackbar(SupplierInvoicesFilter);
