import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import {
  Grid,
  Paper,
  Typography,
  Tooltip,
  FormControlLabel,
  Checkbox,
  TextField,
  ButtonGroup,
  Button
} from '@mui/material';
import {makeStyles} from '@mui/styles';
import {Info} from '@mui/icons-material';
import {formatCurrencyValue} from '../../../util/PaymentUtils';
import classNames from 'classnames';
import {MONTHLY} from "../../../util/BookingUtil";

const useStyles = makeStyles(theme => ({
  chargeOveragesFormPanel: {
    padding: '2.14rem',
    borderRadius: '0.57rem',
    backgroundColor: theme.palette.grey[100],
  },
  gap10: {
    rowGap: '1.43rem',
  },
  fontColorBlack: {
    color: theme.palette.common.black,
  },
  buttonGroup: {
    display: 'flex',
    alignItems: 'center',
    background: "#fff",
    borderRadius: 8,
  },
  button: {
    flexGrow: 1,
    [theme.breakpoints.up('sm')]: {
      width: 'auto',
      minWidth: '10.71em',
    }
  },
  buttonPadding: {
    padding: 6,
    width: "100%",
    display: "flex",
    gap: 10,
    flexWrap: 'wrap',
    justifyContent: 'center',
    [theme.breakpoints.up('sm')]: {
      flexWrap: 'nowrap'
    }
  },
}));

const ChargeOveragesForm = (props) => {
  const {
    disabled,
    locationChargeOverages,
    chargeOverages,
    setChargeOverages,
    customerChargedAmountPerPeriod,
    partnerPayoutAmountPerPeriod,
    overageCustomerChargeRate,
    overagePartnerPayoutRate,
    onCheckboxChange,
    onTextChange,
    durationType,
    manualPayment
  } = props;

  const classes = useStyles();

  // set chargeOverages to locationChargeOverages on initial render
  useEffect(() => {
    setChargeOverages(chargeOverages === null ? locationChargeOverages : chargeOverages);
  }, [chargeOverages, locationChargeOverages, setChargeOverages]);

  const overagesStatusText = `Location Overages is turned ${disabled ? 'OFF' : 'ON'}`;
  const bookingDuration = durationType === MONTHLY ? 'Month' : 'Day';
  const manualPaymentText = 'A booking set to \'Manual Payment\' means that invoices will be generated (booking invoices and overage invoices), but the accounting team will need to manually send an invoice to the buyer, collect funds and payout the supplier outside of the SecurSpace system.';

  return (
    <Paper className={classes.chargeOveragesFormPanel} component='section'>
      <Grid container direction='column' className={classes.gap10}>
        <Grid item container justifyContent='space-between' alignItems='center'>
          <Typography variant='subtitle2' className={classes.fontColorBlack}>Charge Overages</Typography>
          <Tooltip title={overagesStatusText} placement='left'>
            <Info color='primary'/>
          </Tooltip>
        </Grid>
        <ButtonGroup aria-label="outlined primary button group" className={classes.buttonGroup}>
          <Grid className={classes.buttonPadding}>
            <Button
              className={classNames(classes.button, `d-flex ss-tab-btn`)}
              onClick={() => setChargeOverages(false)}
              variant={!chargeOverages ? "contained" : "outlined"}
              color={!chargeOverages ? "primary" : "secondary"}
              disabled={disabled}
            >
              No
            </Button>
            <Button
              className={classNames(classes.button, `d-flex ss-tab-btn`)}
              onClick={() => setChargeOverages(true)}
              variant={chargeOverages ? "contained" : "outlined"}
              color={chargeOverages ? "primary" : "secondary"}
              disabled={disabled}
            >
              Yes
            </Button>
          </Grid>
        </ButtonGroup>

        <Grid item>
            <Grid item container justifyContent='space-between' alignItems='center'>
              <FormControlLabel
                label={<Typography variant='body2' color='textPrimary'>Manual Payment</Typography>}
                control={<Checkbox name='manualPayment' checked={manualPayment} onChange={onCheckboxChange}/>}
              />
              <Tooltip title={manualPaymentText} placement='left'>
                <Info color='primary' />
              </Tooltip>
            </Grid>
        </Grid>

        <TextField
          name='customerChargedAmountPerPeriod'
          value={formatCurrencyValue(customerChargedAmountPerPeriod, true)}
          label={`Customer Charged Amount Per ${bookingDuration}`}
          placeholder={`Enter the customer change /${bookingDuration}`}
          onChange={onTextChange}
          fullWidth
          required
          variant='standard'
        />
        <TextField
          name='partnerPayoutAmountPerPeriod'
          value={formatCurrencyValue(partnerPayoutAmountPerPeriod, true)}
          label={`Partner Payout Amount Per ${bookingDuration}`}
          placeholder={`Enter the Partner payout /${bookingDuration}`}
          onChange={onTextChange}
          error={partnerPayoutAmountPerPeriod > customerChargedAmountPerPeriod}
          helperText={partnerPayoutAmountPerPeriod > customerChargedAmountPerPeriod ? 'Partner payout amount is greater than customer charged amount' : ''}
          fullWidth
          required
          variant='standard'
        />
        <TextField
          name='overageCustomerChargeRate'
          value={formatCurrencyValue(overageCustomerChargeRate, true)}
          label='Overage Customer Charge Rate'
          placeholder='Enter the Customer Overage rate'
          onChange={onTextChange}
          fullWidth
          required
          disabled={!chargeOverages}
          variant='standard'
        />
        <TextField
          name='overagePartnerPayoutRate'
          value={formatCurrencyValue(overagePartnerPayoutRate, true)}
          label='Overage Partner Payout Rate'
          placeholder='Enter the Partner Overage payout'
          onChange={onTextChange}
          fullWidth
          required
          disabled={!chargeOverages}
          variant='standard'
        />
      </Grid>
    </Paper>
  );
};

ChargeOveragesForm.propTypes = {
  disabled: PropTypes.bool,
  customerChargedAmountPerPeriod: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  partnerPayoutAmountPerPeriod: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  overageCustomerChargeRate: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  overagePartnerPayoutRate: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  onCheckboxChange: PropTypes.func,
  onTextChange: PropTypes.func,
  durationType: PropTypes.string.isRequired
};

export default ChargeOveragesForm;
