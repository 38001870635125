import React, { useContext } from 'react'
import { Route } from "react-router";
import { AppContext } from "../../context/app-context";
import { withAccessControl } from "../ProtectedRoute";
import AccessControlBuilder from "../AccessControlBuilder";

import BookedSpaces from "../../views/BookedSpaces";
import BuyerInventoryReport from "../../views/BuyerInventoryReport";
import BuyerInterchangeReport from "../../views/BuyerInterchangeReport";
import BuyerInvoicesReport from "../../views/BuyerInvoicesReport";
import BuyerBookedSpaceCalendarReport from "../../views/BuyerBookedSpaceCalendarReport";
import ServiceFeeApproval from "../../components/serviceFees/customer/ServiceFeeApproval";
import BookingPayment from '../../views/booking-payment/BookingPayment';
import BookingConfirmation from '../../views/booking-confirmation/BookingConfirmation';
import ReportOverview from "../../views/report-overview/ReportOverview";
import ExternalInvoice from '../../views/errors/ExternalInvoice';
import { useFlags } from "launchdarkly-react-client-sdk";
import { Navigate } from "react-router-dom";
import BookingsOverview from "../../views/bookings-overview/BookingsOverview";
import BuyerInventoryCorrections from "../../views/inventory-correction/BuyerInventoryCorrections";

const buyerOwnerAuthorization = new AccessControlBuilder().allowBuyerOwner().build()
const allBuyerAuthorization = new AccessControlBuilder().allowAllBuyer().build()

const ProtectedBookedSpaces = withAccessControl(BookedSpaces, buyerOwnerAuthorization)
const ProtectedPaymentPage = withAccessControl(BookingPayment, allBuyerAuthorization)
const ProtectedBookingConfirmation = withAccessControl(BookingConfirmation, allBuyerAuthorization)
const ProtectedBuyerInventoryReport = withAccessControl(BuyerInventoryReport, allBuyerAuthorization)
const ProtectedBuyerInterchangeReport = withAccessControl(BuyerInterchangeReport, allBuyerAuthorization)
const ProtectedBuyerInvoicesReport = withAccessControl(BuyerInvoicesReport, allBuyerAuthorization)
const ProtectedBuyerBookedSpaceCalendarReport = withAccessControl(BuyerBookedSpaceCalendarReport, buyerOwnerAuthorization)
const ProtectedBuyerInvoiceReport = withAccessControl(ServiceFeeApproval, allBuyerAuthorization)
const ProtectedReportOverview = withAccessControl(ReportOverview, allBuyerAuthorization)
const ProtectedRedesignBuyerBookedSpaces = withAccessControl(BookingsOverview, buyerOwnerAuthorization);
const ProtectedExternalInvoice = withAccessControl(ExternalInvoice, buyerOwnerAuthorization)
const ProtectedBuyerInventoryCorrections = withAccessControl(BuyerInventoryCorrections, buyerOwnerAuthorization)

const BuyerContent = (readPendingBooking) => {

    const appContext = useContext(AppContext)
    const { user, logout } = appContext
    const { buyerBookingsNavRedesign, buyerInventoryNavRedesign, buyerSpaceUsageRedesign, customerInterchangesRedesign, customerInvoicesRedesign, inventoryCorrection } = useFlags();

    return [
        <Route path="/my-bookings" key="/my-bookings" element={
            buyerBookingsNavRedesign ? <Navigate to="/bookings"  /> : <ProtectedBookedSpaces account={user} handleLogout={logout} />}>
        </Route>,
        <Route path="/location-profile/:profileId/payment" key="/booking-payment" element={<ProtectedPaymentPage account={user} handleLogout={logout} />} >

        </Route>,
        <Route path="/booking-confirmation/:bookingId" key="/booking-confirmation" element={<ProtectedBookingConfirmation account={user} handleLogout={logout} />} >

        </Route>,
        <Route path="/buyer-inventory" key="/buyer-inventory" element={
            buyerInventoryNavRedesign ?
                <Navigate to='/reports/buyer-inventory' />
                :
                <ProtectedBuyerInventoryReport account={user} handleLogout={logout} />
        }>

        </Route>,
        <Route path="/buyer-interchanges" key="/buyer-interchanges" element={
            customerInterchangesRedesign ?
                <Navigate to='/reports/interchanges' />
                :
                <ProtectedBuyerInterchangeReport account={user} handleLogout={logout} />
        }>

        </Route>,
        <Route path="/buyer-invoices" key="/buyer-invoices" element={
            customerInvoicesRedesign ?
            <Navigate to='/reports/buyer-invoices' />
            :
            <ProtectedBuyerInvoicesReport account={user} handleLogout={logout} />
            
        }>

        </Route>,
        <Route path="/buyer-booked-space-calendar" key="/buyer-booked-space-calendar" element={ 
            buyerSpaceUsageRedesign ? 
            <Navigate to="/reports/space-usage"  /> 
            : 
            <ProtectedBuyerBookedSpaceCalendarReport account={user} handleLogout={logout} />}>
        </Route>,
        <Route path={"/buyer/service-fee/:transactionId"} key={"/buyer/service-fee/:transactionId"} element={<ProtectedBuyerInvoiceReport account={user} handleLogout={logout} />} >

        </Route>,
        <Route path="/reports/buyer-inventory" key="/reports/buyer-inventory" element={<ProtectedReportOverview account={user} handleLogout={logout} initialTab={0}/>}>

        </Route>,
        <Route path="/reports/interchanges" key="/reports/interchanges"  element={<ProtectedReportOverview account={user} handleLogout={logout} initialTab={1}/>}></Route>,
        <Route path="/reports/buyer-invoices" key="/reports/buyer-invoices"  element={<ProtectedReportOverview account={user} handleLogout={logout} initialTab={2}/>}></Route>,
        <Route path="/reports/space-usage" key="/reports/space-usage"  element={<ProtectedReportOverview account={user} handleLogout={logout} initialTab={3}/>}></Route>,
        <Route path="/bookings" key="/bookings"  element={<ProtectedRedesignBuyerBookedSpaces account={user} handleLogout={logout} readPendingBooking={readPendingBooking} />}></Route>,
        <Route path="/buyer-invoice/:invoiceNumber" key="/invoice" element={<ProtectedExternalInvoice type="overage" />}></Route>,
        <Route path="/buyer-inventory-corrections" key="/buyer-inventory-corrections" element={
            inventoryCorrection ? <ProtectedBuyerInventoryCorrections account={user} handleLogout={logout} /> : <Navigate to={'/'}/>
        }>
        </Route>,
    ]
}

export default BuyerContent
