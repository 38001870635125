import React, {useState} from "react";
import { Menu, MenuItem, Grid, Button } from "@mui/material";
import { downloadCSVFile } from "../../util/FileUtils";
import { getBuyerSpaceUsageGroupSummaryUrl } from "./request/space-usage-requests";
import { getSupplierSpaceUsageGroupSummaryReportUrl } from "../../requests/space-utilization-requests";
import request from "superagent";
import DownloadCsvIcon from "../icons/DownloadCsvIcon";

export const groupByOptions = Object.freeze({
    BUYER: "buyerCompanyName",
    SUPPLIER: "supplierCompanyName",
    LOCATION: "location",
    NONE: "NONE"
  });

const DownloadSpaceUsageCSVButton = ({query, onError, isBuyer}) => {
    const [menuAnchorEl, setMenuAnchorEl] = useState();

    const menuOpen = Boolean(menuAnchorEl);

    const handleClickMenuButton = (event) => {
        setMenuAnchorEl(event.currentTarget);
    };

    const handleCloseMenu = () => {
        setMenuAnchorEl(null);
    };

    const handleExportByGroup = async (group) => {
        if(query && group) {
            let newQuery = Object.assign({}, query);
            newQuery.groupBy = group;
    
            if (isBuyer) {
                downloadFile(getBuyerSpaceUsageGroupSummaryUrl(newQuery))
            } else {
                downloadFile(getSupplierSpaceUsageGroupSummaryReportUrl(newQuery))
            }
        }

    }

    const downloadFile = async (url) => {
        try {
            const {text, headers} = await request.get(url);
            const rawFileName = headers['content-disposition'].split('filename=')[1];
            const filename = rawFileName.match(/"([^"]+)"/)[1];
            downloadCSVFile(text, filename);
        } catch(error) {
            if (error.status === 401) {
                logout();
            }
            onError && onError(error);
        }
    };

    return (
        <Grid item>
            <Grid item>
                <Button variant={"contained"} startIcon={<DownloadCsvIcon/>} onClick={handleClickMenuButton}>Export Group Summaries</Button>
            </Grid>
            <Menu open={menuOpen} anchorEl={menuAnchorEl} onClose={handleCloseMenu}>
                {
                    isBuyer ?
                        <MenuItem onClick={() => handleExportByGroup(groupByOptions.SUPPLIER)}>
                            Group By Supplier
                        </MenuItem>
                    :
                        <MenuItem onClick={() => handleExportByGroup(groupByOptions.BUYER)}>
                            Group By Buyer
                        </MenuItem>
                }
                    <MenuItem onClick={() => handleExportByGroup(groupByOptions.LOCATION)}>
                        Group By Location
                    </MenuItem>
                </Menu>
        </Grid>
            )
}

export default DownloadSpaceUsageCSVButton;