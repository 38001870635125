import React, {Component} from 'react';
import {formatCurrencyValue} from "../util/PaymentUtils";
import AccountReport from "./AccountReport";
import getDisplayValue from "../components/SupplierTransactionStatus";
import URLUtils from "../util/URLUtils";
import {BookingTransactionType, BookingTransactionTypeLabel} from "../components/constants/securspace-constants";
import {loadSupplierTransactions} from "../requests/transaction-requests";
import {getErrorMessageForNonStandardAndStandardResponse} from "../util/NetworkErrorUtil";
import {toast} from "react-toastify";

class SupplierInvoicesReport extends Component {

    constructor(props) {
        super(props);

        let initialSearchText = URLUtils.getQueryVariable('invoiceNumber');
        if (!initialSearchText) {
            initialSearchText = '';
        }

        this.state = {
            account: this.props.account,
            initialSearchText: initialSearchText,
            bookingTransactions: [],
        };
    }

    componentDidMount() {
      this.loadTransactions();
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.account !== this.state.account) {
            this.setState({account: nextProps.account});
            this.loadTransactions();
        }
    }

  loadTransactions() {
    loadSupplierTransactions(this.props.account.id)
      .then(response => {
        const formattedTransactions = [];
        response.body.forEach(transaction => {
          if (transaction.firstIntervalTransactionEndDate && transaction.firstIntervalTransactionStartDate) {
            transaction.endDate = transaction.firstIntervalTransactionEndDate;
            transaction.startDate = transaction.firstIntervalTransactionStartDate;
          }
          formattedTransactions.push(transaction);
        });
        this.setState({bookingTransactions: formattedTransactions});
      })
      .catch(error => {
        toast.error(getErrorMessageForNonStandardAndStandardResponse(error));
      });
  }

    render() {
        return (
            <div className="flex h-100">
                <AccountReport title="Invoices"
                               parentMenu="Finances"
                               data={this.state.bookingTransactions}
                               defaultSortBy="transactionNumber"
                               defaultSortByDirection="DESC"
                               defaultDaysInDateRange={30}
                               visibleRecordBatchSize={20}
                               criteriaField="locationName"
                               dateField="createdOn"
                               initialSearchText={this.state.initialSearchText}
                               reportFields={[
                                   {
                                       label: "LOCATION",
                                       name: "locationName"
                                   },
                                   {
                                       label: "CUSTOMER",
                                       name: "buyerCompanyName"
                                   },
                                   {
                                       label: "TYPE",
                                       name: "transactionType",
                                       formatter: (type) => {
                                           return BookingTransactionTypeLabel[type] || type;
                                       }
                                   },
                                   {
                                       label: "SPACES",
                                       name: "numberOfSpaces",
                                       groupable: false
                                   },
                                   {
                                       label: "AMOUNT",
                                       name: "supplierAmount",
                                       formatter: (value) => {
                                           return formatCurrencyValue(value);
                                       },
                                       groupable: false
                                   },
                                   {
                                       label: "STATUS" ,
                                       name: "status",
                                       formatter: (status, invoice) => {
                                         if (invoice.transactionType === BookingTransactionType.SERVICE_CHARGE) {
                                           return 'Awaiting Customer Approval'
                                         } else {
                                           return getDisplayValue(status)
                                         }
                                       }
                                   },
                                   {
                                       label: "Start Date",
                                       name: "startDate",
                                       shouldShowField: (transaction) => {
                                        return transaction.transactionType !== BookingTransactionType.SERVICE_CHARGE;
                                       }
                                   },
                                   {
                                       label: "End Date",
                                       name: "endDate",
                                       shouldShowField: (transaction) => {
                                        return transaction.transactionType !== BookingTransactionType.SERVICE_CHARGE;
                                       }
                                   },
                                   {
                                       label: "INVOICE CREATED",
                                       name: "createdOn"
                                   },
                                   {
                                       label: "PAYMENT INITIATED",
                                       name: "paymentCreatedOn"
                                   },
                                   {
                                       label: "PAYMENT COMPLETED",
                                       name: "paymentCompletedOn"
                                   },
                                   {
                                       label: "PAYOUT INITIATED",
                                       name: "payoutCreatedOn"
                                   },
                                   {
                                       label: "PAYOUT COMPLETED",
                                       name: "payoutCompletedOn"
                                   },
                                   {
                                       label: "INVOICE NUMBER",
                                       name: "transactionNumber",
                                       groupable: false
                                   },
                                   {
                                       label: "PAYMENT TYPE",
                                       name: "paymentType",
                                       groupable: false,
                                       searchable: false
                                   },
                                   {
                                       label: "CHARGED DATE",
                                       name: "createdOn"
                                   },
                                   {
                                       label: "BOOKING",
                                       name: "bookingNumber"
                                   },
                                   {
                                     label: "Equipment Type",
                                     name: "assetType"
                                   },
                                   {
                                     label: "Notes",
                                     name: "statusNotes",
                                     shouldShowField: (transaction) => {
                                       return transaction.transactionType === BookingTransactionType.SERVICE_CHARGE;
                                     }
                                   }
                               ]}
                               account={this.state.account}
                />

            </div>
        )
    }
}

export default SupplierInvoicesReport;
