import React from "react";
import ReportDataRow from "../../report/ReportDataRow";
import ReportData from "../../report/ReportData";
import {formatCurrencyValue} from "../../../util/PaymentUtils";
import {ChargeTypes, PaymentProcessor} from "../../constants/securspace-constants";
import {NavLink} from "react-router-dom";
import getDisplayValue from "../../SupplierTransactionStatus";
import {formatBool, formatDate} from "../../../util/BookingUtil";
import ReportDataWithIcon from "../../report/ReportDataWithIcon";
import ReportDataWithStatus from "../../report/ReportDataWithStatus";

const InvoiceItem = (props) => {

  const {invoice} = props;

  const navLink = (invoice) => {
      return (
        <NavLink to={{
          pathname: '/admin-bookings',
          search: "bookingNumber=" + invoice.bookingNumber
        }}>
          {invoice.bookingNumber}
        </NavLink>
      );
  }

  return <ReportDataRow>
    <ReportData label={"INVOICE NUMBER"}>{invoice.transactionNumber}</ReportData>
    <ReportDataWithIcon cssIconType="date" label={"SERVICE DATES"}>{invoice.serviceDates}</ReportDataWithIcon>
    <ReportData label={"CUSTOMER AMOUNT"}>{formatCurrencyValue(invoice.buyerAmount)}</ReportData>
    <ReportDataWithIcon cssIconType="amount" label={"PAYMENT PROCESSOR AMOUNT"}>{formatCurrencyValue(invoice.paymentProcessorFees)}</ReportDataWithIcon>
    <ReportDataWithIcon cssIconType="amount" label={"PARTNER AMOUNT"}>{formatCurrencyValue(invoice.supplierAmount)}</ReportDataWithIcon>
    <ReportData label={"LOCATION CITY"}>{invoice.supplierCity}</ReportData>
    <ReportData label={"LOCATION STATE"}>{invoice.supplierState}</ReportData>
    <ReportData label={"BOOKING STATE"}>{invoice.locationState}</ReportData>
    <ReportDataWithIcon cssIconType="amount" label={"SECURSPACE AMOUNT"}>{formatCurrencyValue(invoice.securspaceFees)}</ReportDataWithIcon>
    <ReportData label={"CHARGE TYPE"}>{ChargeTypes[invoice.transactionType]}</ReportData>
    <ReportData label={"INVOICE CREATED"}>{formatDate(invoice.createdOn)}</ReportData>
    <ReportData label={"PAYMENT TYPE"}>{invoice.paymentType}</ReportData>
    <ReportData label={"PAYMENT INITIATED"}>{formatDate(invoice.paymentCreatedOn)}</ReportData>
    <ReportData label={"PAYMENT COMPLETED"}>{formatDate(invoice.paymentCompletedOn)}</ReportData>
    <ReportData label={"PAYOUT INITIATED"}>{formatDate(invoice.payoutCreatedOn)}</ReportData>
    <ReportData label={"PAYOUT COMPLETED"}>{formatDate(invoice.payoutCompletedOn)}</ReportData>
    <ReportData label={"CUSTOMER"}>{invoice.buyerCompanyName}</ReportData>
    <ReportData label={"CUSTOMER EMAIL"}>{invoice.buyerEmail}</ReportData>
    <ReportData label={"PAYMENT PROCESSOR"}>{PaymentProcessor[invoice.paymentProcessor]}</ReportData>
    <ReportDataWithIcon cssIconType="location" label={"LOCATION"}>{invoice.locationName}</ReportDataWithIcon>
    <ReportData label={"PARTNER"}>{invoice.supplierCompanyName}</ReportData>
    <ReportData label={"BOOKING"}>{navLink(invoice)}</ReportData>
    <ReportData label={"SPACES"}>{invoice.numberOfSpaces}</ReportData>
    <ReportDataWithStatus title={getDisplayValue(invoice.status)} label={"STATUS"}>{getDisplayValue(invoice.status)}</ReportDataWithStatus>
    <ReportData label={"PAYOUT ON HOLD"}>{formatBool(invoice.supplierPayoutOnHold)}</ReportData>
    {invoice.transferType === 'REFUND' &&
      <ReportData label={"INCLUDE REFUND IN PAYOUT"}>{formatBool(invoice.includeRefundInPayout)}</ReportData>
    }
    <ReportData label={"BOOKING CREATED ON"}>{formatDate(invoice.bookingCreatedOn)}</ReportData>
    <ReportData label={"BOOKING CREATED BY"}>{invoice.bookingCreatedBy}</ReportData>
    {invoice.refundRequestedBy &&
      <ReportData label={"REFUND REQUESTED BY"}>{invoice.refundRequestedBy}</ReportData>
    }
    {invoice.reasonForRefund &&
      <ReportData label={"REFUND REASON"}>{invoice.reasonForRefund}</ReportData>
    }
    <ReportData label={"EQUIPMENT TYPE"}>{invoice.assetType}</ReportData>
  </ReportDataRow>
}

export default InvoiceItem;
